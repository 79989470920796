import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as WSCall from '@app/utils/WSCall';
import * as Utils from '@app/utils/Utils.ts';
import { SessionControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/sessionController.service';
import { AttendantPerCourseResult } from '@proflink/prof-link-web-ic-api-ts-angular/model/attendantPerCourseResult';
import { RegisterSessionControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/registerSessionController.service';
import { PublicSessionControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/publicSessionController.service';
import { environment } from 'environments/environment';
import Swal from 'sweetalert2';
import { Configuration } from '@proflink/prof-link-web-ic-api-ts-angular';
import { SessionModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/sessionModel';
import { ResultSessionModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/resultSessionModel';
import { RegisterSessionModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/registerSessionModel';
import { DateAndAttendantResult } from '@proflink/prof-link-web-ic-api-ts-angular/model/dateAndAttendantResult';
import { ResultScoreDataModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/resultScoreDataModel';
import { SessionSummaryModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/sessionSummaryModel';
import { QuizFormModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/quizFormModel';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-tab-course-checkin-checkout',
  templateUrl: './tab-course-checkin-checkout.component.html',
  styleUrls: ['./tab-course-checkin-checkout.component.scss']
})
export class TabCourseCheckinCheckoutComponent implements OnInit {

  constructor(
    private _datePipe: DatePipe,
    private _sessionControllerService: SessionControllerService,
    private _registerSessionControllerService: RegisterSessionControllerService,
    private _publicSessionController: PublicSessionControllerService,
    private _apiConfig: Configuration,
    private _translate: TranslateService
  ) {
    this._translate.use(localStorage.getItem('language'));
  }


  arrayCollapses: Array<boolean> = [];
  isCollapsed = false;

  totalLearningMinutes = 0;
  totalCourseProgressMinutes = 0;
  totalCourseMinutes = 0;

  percentTotalLearning = 0;
  percentTotalCourseProgress = 0;
  checkedData: Array<string> = [];
  session: SessionModel;
  attendantPerCourses: Array<AttendantPerCourseResult> = [];

  dateAndAttendant: Array<DateAndAttendantResult> = [];


  resultScoreData: Array<ResultScoreDataModel> = [];

  mapPostTestScore: Map<string, string> = new Map();
  mapPostTestFullScore: Map<string, string> = new Map();
  mapPostTestAvg: Map<string, string> = new Map();
  mapPostTestMin: Map<string, string> = new Map();
  mapPostTestSd: Map<string, string> = new Map();
  mapPostTestMax: Map<string, string> = new Map();

  mapPreTestScore: Map<string, string> = new Map();
  mapPreTestFullScore: Map<string, string> = new Map();
  mapPreTestAvg: Map<string, string> = new Map();
  mapPreTestMin: Map<string, string> = new Map();
  mapPreTestSd: Map<string, string> = new Map();
  mapPreTestMax: Map<string, string> = new Map();


  quizsatData: Array<QuizFormModel> = [];
  preScore = '-/-';
  postScore = '-/-';
  preScoreMin = 0;
  preScoreMax = 0;
  preScoreAvg = 0;
  preScoreSD = 0;
  postScoreMin = 0;
  postScoreMax = 0;
  postScoreAvg = 0;
  postScoreSD = 0;
  keyPreTest = 1;
  keyPostTest = 2;
  registerSession: ResultSessionModel;

  language: string = 'th';
  titleBox: string = '';
  titlePassword: string = '';
  requireCode: string = '';
  btnConfirm: string = '';
  titleError: string = '';
  msgError: string = '';

  @Input('registerSession')
  set setRegisterSession(registerSession: ResultSessionModel) {
    if (registerSession != null) {
      this.registerSession = registerSession;
      //console.log(this.registerSession);
      //console.log('A', this.registerSession);
    }
  }

  @Input('session')
  set setSession(session: SessionModel) {
    if (session != null) {
      this.session = session;
      // console.log(this.session);
      this.loadSession(this.session);
    }
  }

  ngOnInit() {
    this.language = localStorage.getItem('language');
  }

  async loadSession(session: SessionModel) {
    this.attendantPerCourses = await this.getCourseAttendantByIc(this.session.sessionId);
    this.dateAndAttendant = await this.getAttendantTimeByDate(this.session.sessionId);
    if (this.registerSession != null) {
      this.quizsatData = await this.getListActiveQuizForms(this.registerSession);
      this.resultScoreData = await this.getIcExamStat(this.registerSession);
    }

    // let registerSessionBySid: RegisterSessionModel = await this.getRegisterSessionBySid(this.session.sessionId);
    this.summaryCourse(this.attendantPerCourses);
    this.calculateScore();
  }

  private calculateScore(): void {
    if (this.resultScoreData.length > 0) {
      this.resultScoreData.forEach(data => {
        if (data.type === this.keyPreTest) {
          let min = data.statMin;
          const max = data.statMax;
          if (min > max) {
            min = 0;
          }

          this.mapPreTestScore.set(data.courseCode, data.score.toString());
          this.mapPreTestFullScore.set(data.courseCode, data.maxScore.toString());
          this.mapPreTestAvg.set(data.courseCode, data.statAvg.toFixed(1).toString());
          this.mapPreTestSd.set(data.courseCode, data.statSd.toFixed(1).toString());
          this.mapPreTestMin.set(data.courseCode, data.statMin.toString());
          this.mapPreTestMax.set(data.courseCode, data.statMax.toString());

          this.preScoreMin = min;
          this.preScoreMax = max;
          this.preScoreAvg = data.statAvg;
          this.preScoreSD = data.statSd;
          if (data.absent) {
            this.preScore = 'ABSENT';
          } else if (!data.didTest) {
            this.preScore = '-/-';
          } else {
            this.preScore = data.score + '/' + data.maxScore;
          }
        }
        if (data.type === this.keyPostTest) {

          this.mapPostTestScore.set(data.courseCode, data.score.toString());
          this.mapPostTestFullScore.set(data.courseCode, data.maxScore.toString());
          this.mapPostTestAvg.set(data.courseCode, data.statAvg.toFixed(1).toString());
          this.mapPostTestSd.set(data.courseCode, data.statSd.toFixed(1).toString());
          this.mapPostTestMin.set(data.courseCode, data.statMin.toString());
          this.mapPostTestMax.set(data.courseCode, data.statMax.toString());


          let min = data.statMin;
          const max = data.statMax;
          if (min > max) {
            min = 0;
          }
          this.postScoreMin = min;
          this.postScoreMax = max;
          this.postScoreAvg = data.statAvg;
          this.postScoreSD = data.statSd;
          if (data.absent) {
            this.postScore = 'ABSENT';
          } else if (!data.didTest) {
            this.postScore = '-/-';
          } else {
            this.postScore = data.score + '/' + data.maxScore;
          }
        }
      });
    }
  }

  summaryCourse(attendantPerCourses: Array<AttendantPerCourseResult>) {

    if (attendantPerCourses == null) {
      return;
    }

    attendantPerCourses.forEach(item => {
      this.totalLearningMinutes = this.totalLearningMinutes + item.learningMinutes;
      this.totalCourseProgressMinutes = this.totalCourseProgressMinutes + item.courseDurationTillNowMinutes;
      this.totalCourseMinutes = this.totalCourseMinutes + item.totalCourseMinutes;
    });

    if (this.totalCourseMinutes > 0) {
      this.percentTotalLearning = (this.totalLearningMinutes * 100) / this.totalCourseMinutes;
      this.percentTotalCourseProgress = (this.totalCourseProgressMinutes * 100) / this.totalCourseMinutes;
    }
  }

  getCheckInOutTime(date: string, checkedType: string, sectionStartTime: number, sectionEndTime: number) {
    this.checkedData = [];
    let data = this.dateAndAttendant.filter(x => x.dateStr === date
      && x.attendantByDate.checkedType === checkedType
      && x.attendantByDate.sectionStartTime === sectionStartTime
      && x.attendantByDate.sectionEndTime === sectionEndTime);

    if (data.length > 0) {
      this.checkedData.push(this._datePipe.transform(data[0].attendantByDate.checkedDate, 'HH:mm:ss'));
      switch (data[0].attendantByDate.scanType) {
        case 0:
          if (data[0].attendantByDate.checkedDate != null && data[0].attendantByDate.checkedStatus) {
            this.checkedData.push('Kiosk');
          }
          break;
        case 1:
          this.checkedData.push('QR Code');
          break;
        case 2:
          this.checkedData.push('ID Card');
          break;
        case 3:
          this.checkedData.push('TP');
          break;
      }
    }
    return this.checkedData;
  }

  getDateDisplay(time: string) {

    let trainingDate: Date = new Date(time);
    return this._datePipe.transform(trainingDate, 'dd MMM yyyy', null, this.language == 'th' ? null : 'en');
  }

  getDateTime(min: number) {

    let displayTime = new Date();
    displayTime.setHours(0, min, 0, 0);

    return this._datePipe.transform(displayTime, 'HH:mm');

  }

  getPercentTotalLearning(atPc: AttendantPerCourseResult) {
    let percentLearningMinutes: number = 0;
    percentLearningMinutes = (atPc.learningMinutes * 100) / atPc.totalCourseMinutes;
    return percentLearningMinutes.toFixed(0);
  }

  getPercentTotalCourseProgress(atPc: AttendantPerCourseResult) {
    let percentCourseProgress: number = 0;
    percentCourseProgress = (atPc.courseDurationTillNowMinutes * 100) / atPc.totalCourseMinutes;
    return percentCourseProgress.toFixed(0);

  }

  async getSessionSummaryBySessionId(sid: number): Promise<SessionSummaryModel> {
    return WSCall.wsCall(
      () => {
        return this._publicSessionController.getSessionSummaryBySessionIdUsingGET(sid);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getRegisterSessionBySid(sid: number): Promise<RegisterSessionModel> {
    return WSCall.wsCall(
      () => {
        return this._registerSessionControllerService.getRegisterSessionBySidUsingGET(sid);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getIcExamStat(registerSession: RegisterSessionModel): Promise<Array<ResultScoreDataModel>> {
    if (registerSession == null) {
      return;
    }

    if (registerSession.registerSessionId != null) {
      return WSCall.wsCall(
        () => {
          return this._sessionControllerService.getIcExamStatFromWebUsingGET(registerSession.registerSessionId);
        },
        respBody => {
          return respBody.data;
        }
      );
    }

  }

  async getAttendantTimeByDate(sid: number): Promise<Array<DateAndAttendantResult>> {
    return WSCall.wsCall(
      () => {
        return this._sessionControllerService.getAttendantTimeByDateUsingGET(sid);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getCourseAttendantByIc(sid: number): Promise<Array<AttendantPerCourseResult>> {
    return WSCall.wsCall(
      () => {
        return this._sessionControllerService.getCourseAttendantByIcUsingGET(sid);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getListActiveQuizForms(registerSession: RegisterSessionModel): Promise<Array<QuizFormModel>> {
    return WSCall.wsCall(
      () => {
        return this._sessionControllerService.getListActiveQuizFormsUsingGET(registerSession.registerSessionId);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  getLinkDownload(registerSession: RegisterSessionModel) {

    let linkDownload: string = null;

    if (registerSession == null) {
      return linkDownload;
    }

    if (registerSession.certificate != null && registerSession.certificate.downloadLink) {
      return registerSession.certificate.downloadLink;
    }

    return linkDownload;

  }


  getPreTestScore(atPc: AttendantPerCourseResult): string {
    return 'Max. 0 Min. 0 Avg. 0 S.D. 0';
  }


  async beginRedirectUrl(quizFormId: number): Promise<any> {
    {
      //my-course-detail-share/463/1
      let source = location.origin + '/my-course-detail-share/' + this.session.sessionId + '/1';
      let url = '';
      if (environment.baseUrl.includes('dev')) {
        url = 'https://dev-proflink.set.or.th';
      } else if (environment.baseUrl.includes('test')) {
        url = 'https://test-proflink.set.or.th';
      } else if (environment.baseUrl.includes('localhost')) {
        url = 'http://localhost:4201';
      } else {
        url = 'https://proflink.set.or.th';
      }
      window.location.href = url +
        '/prepost-test?mobile=1&qf=' +
        quizFormId +
        '&source=' + source +
        '&local=th&token=' +
        this._apiConfig.accessToken;
    }
  }
  async beginPrePostTest(quizFormId: number, code: string): Promise<any> {
    this._translate.get('MY_COURSE.BOX_SATISFACTION').subscribe((res: string) => {
      this.titleBox = res;
    });
    this._translate.get('MY_COURSE.BOX_PASSWORD').subscribe((res: string) => {
      this.titlePassword = res;
    });
    this._translate.get('MY_COURSE.REQUIRE_CODE').subscribe((res: string) => {
      this.requireCode = res;
    });
    this._translate.get('BUTTON.CONFIRM').subscribe((res: string) => {
      this.btnConfirm = res;
    });
    const { value: entryCode } = await Swal.fire({
      title: this.titleBox,
      text: this.titlePassword,
      input: 'text',
      showConfirmButton: true,
      confirmButtonText: this.btnConfirm,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return this.requireCode;
        } else {
          if (value === code) {
            this.beginRedirectUrl(quizFormId);
          } else {
            this._translate.get('UTILS.TITLE_ERROR').subscribe((res: string) => {
              this.titleError = res;
            });
            this._translate.get('UTILS.PASSWORD_ERROR').subscribe((res: string) => {
              this.msgError = res;
            });

            Utils.error(this.msgError, this.titleError);
          }
        }
      }
    });
  }
}
