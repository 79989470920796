import { ChangeDetectorRef, HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as WSCall from "@app/utils/WSCall";
import { NoticeControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/noticeController.service";
import { NoticeSummaryResponse } from "@proflink/prof-link-web-ic-api-ts-angular/model/noticeSummaryResponse";
import { AppState } from '@core/app-state';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private _appState: AppState,
    private _router: Router,
    private router: Router,
    private _noticeControllerService: NoticeControllerService,
    private _translate: TranslateService
  ) {
    const currentLang = localStorage.getItem('language');
    this._translate.use(currentLang);
  }

  items: MenuItem[] | undefined;
  profileItems: MenuItem[] | undefined;

  // @HostListener('document:click', ['$event']) click(event: Event) {

  //   if (!$(event.target).is('.collapse-custom *')) {
  //     this.closeCollapse();
  //   }
  // }

  // closeCollapse() {
  //   let elems = document.querySelectorAll(".collapse-custom");

  //   [].forEach.call(elems, function (el: any) {
  //     el.classList.remove("show");
  //   });
  // }

  numNotification: number = 0;

  async ngOnInit() {

    this.loadData();
    forkJoin({
      home: this._translate.get('MENU_BAR.HOME'),
      courseActivity: this._translate.get('MENU_BAR.COURSE_ACTIVITY'),
      myCourse: this._translate.get('MENU_BAR.MY_COURSE'),
      eLearning: this._translate.get('MENU_BAR.E_LEARNING'),
      more: this._translate.get('MENU_BAR.MORE'),
      security: this._translate.get('MENU_BAR.SECURITY'),
      changePin: this._translate.get('MENU_BAR.CHANGE_PIN'),
      personalData: this._translate.get('MENU_BAR.EDIT_PROFILE'),
      help: this._translate.get('MENU_BAR.HELP'),
      condition: this._translate.get('MENU_BAR.TERM_AND_CONTITION'),
      cancel: this._translate.get('MENU_BAR.CANCEL_CONSENT'),
      about: this._translate.get('MENU_BAR.ABOUT'),
      logout: this._translate.get('MENU_BAR.LOGOUT'),
    }).subscribe(translations => {
      this.items = [
        {
          label: translations.home,  // Translated value from ngx-translate
          routerLink: ['home'],
          routerLinkActiveOptions: { exact: true }
        },
        // Hide menu course activity
        // {
        //   label: translations.courseActivity,
        //   routerLink: ['course-activity'],
        //   routerLinkActiveOptions: { exact: true }
        // },
        {
          label: translations.myCourse,
          routerLink: ['mycourse'],
          routerLinkActiveOptions: { exact: true }
        },
        {
          label: translations.eLearning,
          routerLink: ['bannerelearning'],
          routerLinkActiveOptions: { exact: true }
        },
        {
          label: translations.more,
          items: [
            {
              label: translations.security,
              expanded: true,
              items: [
                {
                  label: translations.changePin,
                  routerLink: ['more/privacy-setting/change-pin']
                }
              ]
            },
            {
              label: translations.personalData,
              routerLink: ['profile/personal/1']
            },
            {
              label: translations.help,
              routerLink: ['report-problem'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: translations.condition,
              expanded: true,
              items: [
                {
                  label: translations.condition,
                  routerLink: ['more/condition-and-agreement']
                },
                {
                  label: translations.cancel,
                  routerLink: ['more/privacy-setting/cancellation-and-privacy']
                }
              ]
            }
          ]
        },
        {
          label: translations.about,
          routerLink: ['more/about-us'],
          routerLinkActiveOptions: { exact: true }
        },
        {
          label: translations.logout,
          command: () => this.logout(),
        }
      ];
    });

    this.profileItems = [
      {
        label: 'ข้อมูลส่วนตัว',
        routerLink: ['profile/personal/1']
      },
      {
        label: 'ดิจิตอลพาสปอร์ต',
        routerLink: ['profile/digital-passport']
      },
      {
        label: 'สถานะการอบรมต่ออายุใบอนุญาตฯ',
        routerLink: ['profile/license-status']
      }]
  }

  openNewsAndActivities() {
    this.router.navigate(['news']);
  }

  async loadData() {

    const summaryMyNotification = await this.summaryMyNotification();
    if (summaryMyNotification != null) {
      this.numNotification = summaryMyNotification.unReadNotice;
    }

    console.log('summaryMyNotification : ', summaryMyNotification);

  }

  async summaryMyNotification(): Promise<NoticeSummaryResponse> {
    return WSCall.wsCall(
      () => {
        return this._noticeControllerService.summaryMyNotificationUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  logout() {
    this._appState.logout();
    this._router.navigate(["login"]);
  }


}
