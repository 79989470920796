<div class="box-container">
    <div class="box">
        <div class="content pb-4">
            <div class="d-flex flex-column m-0 p-4">
                <h4 class="text-center">{{'MY_COURSE.SENSITIVE_DATA_CONSENT_TITLE' | translate}}</h4>
                <div style="height: 83vh; width: 100%;">
                    <div class="bloghtml" [innerHTML]="textHtml"></div>
                    <!-- <ng2-pdfjs-viewer #pdfViewerAutoLoad [openFile]="false"
                                      [viewBookmark]="false"></ng2-pdfjs-viewer> -->
                </div>
                <!--                <div class="d-flex justify-content-around pt-2" [formGroup]="isAcceptConsentForm">-->
                <!--                    <div>-->
                <!--                        <input type="radio" id="accept" name="isAcceptConsent" [value]="true" formControlName="isAcceptConsent">-->
                <!--                        <label class="pl-2" style="font-size: 1.25rem;" for="accept">ยอมรับ</label>-->
                <!--                    </div>-->
                <!--                    <div>-->
                <!--                        <input type="radio" id="notAccept" name="isAcceptConsent" [value]="false" formControlName="isAcceptConsent">-->
                <!--                        <label class="pl-2" style="font-size: 1.25rem;" for="notAccept">ไม่ยอมรับ</label>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div class="d-flex">-->
                <!--                    <div class="p-2 flex-fill">-->
                <!--                        <button type="button" [disabled]="!isAcceptConsent.value" class="btn btn-block submit" (click)="accept()">ยืนยัน</button>-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="d-flex justify-content-around">
                    <div class="p-2 flex-fill">
                        <button type="button" [value]="false" class="btn btn-block un-submit"
                            (click)="notAccept()">{{'BUTTON.CANCEL' | translate}}</button>
                    </div>
                    <div class="p-2 flex-fill">
                        <button type="button" [value]="true" class="btn btn-block submit"
                            (click)="accept()">{{'BUTTON.ACKNOWLEDGE' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>