import { Component, OnInit, ViewChild } from '@angular/core';
import { SessionControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/sessionController.service';
import { HttpClient } from '@angular/common/http';
import { ProtectedMiscControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/protectedMiscController.service';
import { FileStoreModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/fileStoreModel';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterSessionControllerService } from '@proflink/prof-link-web-ic-api-ts-angular';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FileUploadComponent } from '@public/file-upload/file-upload.component';
import { fixCORSUrl } from '@app/utils/Utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-attachment-document',
    templateUrl: './attachment-document.component.html',
    styleUrls: ['./attachment-document.component.scss']
})
export class AttachmentDocumentComponent implements OnInit {

    @ViewChild('attachmentDocument')
    attachmentDocument: FileUploadComponent;

    constructor(private sessionControllerService: SessionControllerService,
        private protMiscService: ProtectedMiscControllerService,
        private activatedRoute: ActivatedRoute,
        private registerSessionService: RegisterSessionControllerService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private ngxService: NgxUiLoaderService,
        protected httpClient: HttpClient,
        private _translate: TranslateService) {
        this._translate.use(localStorage.getItem('language'));
    }

    sessionId: number;
    proofOfIdentityFiles: FileStoreModel[] = [];
    title: string = 'เอกสารยืนยันตัวตน';

    ngOnInit(): void {
        this.sessionId = this.activatedRoute.snapshot.params.sessionId;
        this.getProofOfIdentityFiles();
        this._translate.get('MY_COURSE.SD_ATTACH_DOCUMENT_TITLE').subscribe((res: string) => {
            this.title = res;
        });

    }

    upload(): void {
        this.attachmentDocument.fileInputElement.nativeElement.click();
    }

    getProofOfIdentityFiles(): void {
        this.registerSessionService.getRegisterSessionBySidUsingGET(this.sessionId).subscribe(res => {
            this.proofOfIdentityFiles = res.data.proofOfIdentityFiles;
        });
    }
    addFile(fileStoreModel: FileStoreModel): void {
        this.proofOfIdentityFiles.push(fileStoreModel);
    }
    removeFile(removeItem: FileStoreModel): void {
        this.proofOfIdentityFiles = this.proofOfIdentityFiles.filter(item => item !== removeItem);
    }
    save(): void {
        const files = this.proofOfIdentityFiles.map(f => f.fileId);
        this.sessionControllerService.saveProofOfIdentityFilesUsingPOST(this.sessionId, files).subscribe(res => {
            this.router.navigate([`/my-course-detail-share/${this.sessionId}/1/mycourse`]);
        });
    }
    onBackgroundImageChange(file: File): void {
        if (file != null) {
            this.ngxService.start();
            this.sessionControllerService.getUploadLinkForProofOfIdentityUsingGET().subscribe(res => {
                this.attachmentDocument.upload(this.httpClient, this.protMiscService, res.data.fileId,
                    fixCORSUrl(res.data.uploadLinkWeb), () => {
                        this.ngxService.stop();
                        this.addFile(res.data);
                    }, () => {
                        this.ngxService.stop();
                    });
            });
        }
    }

    getImageUrl(item: FileStoreModel): string {
        return 'url(' + this.sanitizer.bypassSecurityTrustUrl(item.attachmentLinkWeb) + ');';
    }

    openFile(item: FileStoreModel): void {
        window.open(item.attachmentLinkWeb, '_blank');
    }
}
