<div class="wrapper">

    <!-- <app-header-with-banner class="header" [title]="'test'"></app-header-with-banner> -->

    <div class="d-flex m-4 align-items-center justify-content-end">
        <div class="language-switcher">
            <div class="selected-lang" (click)="toggleDropdown()">
                <img [src]="currentLangFlag" alt="Selected Language Flag">
                <span>{{ currentLang | uppercase }}</span>
                <span> &#9662;</span>
            </div>
            <ul class="dropdown" *ngIf="dropdownVisible">
                <li *ngFor="let lang of languages" (click)="switchLanguage(lang.code)">
                    <img [src]="lang.flag" alt="{{ lang.name }}">
                    <span>{{ lang.name }}</span>
                </li>
            </ul>
        </div>
    </div>

    <div
        class="container col-sm-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-4 col-xl-4 content custom-input">



        <div class="d-flex m-4 align-items-center justify-content-center">
            <img src="../../../../../assets/image/logo_old.png" width="110px" height="110px"
                style="border: 1px solid #FEA30B; border-radius: 18px;">
        </div>
        <form class="row custom-container">

            <div class="card col-sm-12 m-sm-0 p-0">
                <div class="card-body pt-5 pb-5 pl-0 pr-0 m-sm-0 m-xl-4 m-md-4">
                    <div class="col-sm-12">
                        <div class="col-sm-12">
                            <label>{{'LOGIN_PAGE.T_USERNAME' | translate}}</label>
                        </div>

                        <div class="col-sm-12">
                            <input type="text" name="username" class="form-control" maxlength="13"
                                (change)="onChangeInputUsername()" (keypress)="onEnterKeyUserName($event)"
                                [(ngModel)]="entryData.username" />
                            <span id="usernameError" style="color:red;"></span>
                        </div>
                    </div>

                    <div *ngIf="showFromPassword" class="col-sm-12 pt-3">
                        <div class="col-sm-12">
                            <label>{{'LOGIN_PAGE.T_PIN' | translate}}</label>
                        </div>

                        <div class="col-sm-12">
                            <input type="password" name="password" class="form-control input-password" maxlength="6"
                                (keypress)="onEnterKeyPassword($event)" [(ngModel)]="entryData.password"
                                #inputPassword />
                        </div>
                    </div>

                    <div *ngIf="!canRegister" class="col-sm-12 pt-4 text-center">
                        <div class="col-sm-12">
                            <button type="button" class="btn btn-block btn-login" (click)="submitLogin()">
                                <label class="custom-font">{{'LOGIN_PAGE.B_LOGIN' | translate}}</label>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="canRegister" class="col-sm-12 pt-3 text-center">
                        <div class="col-sm-12">
                            <div class="col-12 pb-3" style="background-color: lightgray;">

                                <div class="p-2">
                                    <span class="text-center custom-font">{{'LOGIN_PAGE.REGISTER_HEADER' |
                                        translate}}<br></span>
                                    <span class="text-center custom-font">{{'LOGIN_PAGE.REGISTER_DESCRIPTION' |
                                        translate}}</span>
                                </div>

                                <button type="button" class="btn btn-block btn-login pb-2"
                                    (click)="registerIcProfile()">
                                    <label class="custom-font">{{'LOGIN_PAGE.REGISTER_BUTTON_TEXT' | translate}}</label>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="canForgotPassword && (!canRegister)" class="col-sm-12 pt-3 text-center">
                        <!-- <div class="col-sm-12 pt-3 text-center"> -->
                        <div class="col-sm-12 text-right">
                            <!-- <a class="btn-forgot" href="javascript:void(0);" (click)="forgotPassword()">Forgot PIN</a> -->
                            <a class="btn-forgot" href="javascript:void(0);"
                                (click)="open(content)">{{'LOGIN_PAGE.T_FORGET' | translate}}</a>
                        </div>
                    </div>

                    <div *ngIf="!canRegister" class="col-sm-12 pt-3">
                        <div class="col-sm-12">
                            <p class="col-form-label-sm pl-1 custom-font bg-remark remark-text">
                                {{'LOGIN_PAGE.T_NOTE' | translate}} <br />
                                {{'LOGIN_PAGE.T_NOTE_DESCRIPTION' | translate}} <br />
                                Username Foreigner - Fill in your Mobile Number (10 Digit)<br />
                                <u>{{'LOGIN_PAGE.T_NOTE_2' | translate}}</u> {{'LOGIN_PAGE.T_NOTE_2_DESCRIPTION' |
                                translate}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">{{'LOGIN_PAGE.T_FORGET' | translate}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card card-body col-md-12 col-sm-12 m-2">
                <a class="btn-forgot" href="javascript:void(0);" (click)="forgotPassword()">{{'LOGIN_PAGE.T_FORGET_PIN'
                    | translate}}</a>
                <span class="text-muted">{{'LOGIN_PAGE.T_FORGET_PIN_DESCRIPTION' | translate}}</span>
            </div>
            <div class="card card-body col-md-12 col-sm-12 m-2">
                <a *ngIf="entryData.username.length===13" class="btn-forgot" href="javascript:void(0);"
                    (click)="changeMobileNumber()">{{'LOGIN_PAGE.T_CHANGE_MOBILE' | translate}}</a>
                <span *ngIf="entryData.username.length!==13" class="btn-forgot"
                    aria-disabled="true">{{'LOGIN_PAGE.T_CHANGE_MOBILE' | translate}}</span>
                <span class="text-muted">{{'LOGIN_PAGE.T_CHANGE_MOBILE_DESCRIPTION_1' | translate}}</span>
            </div>
        </div>
    </ng-template>
</div>