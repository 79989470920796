<app-header-with-back *ngIf="platform != 'mobile'" class="header" [title]="title"
    [path]="'profile/license-status'"></app-header-with-back>
<div class="box-container">
    <div class="box">
        <div *ngIf="icLicenseSummaryV2Result != null" class="container">
            <div class="d-flex flex-column justify-content-center">
                <div class="pt-3 d-flex flex-row justify-content-center">
                    <label class="title">{{'LICENSE_STATUS_PAGE.T_HEADER_DETAIL' | translate}}</label>
                </div>

                <div class="p-0 d-flex flex-row justify-content-center">
                    <div class="pt-2 pb-2 pr-4 pl-4 mr-2 ml-2 d-flex flex-row d-inline-flex summary-border">
                        <label class="m-2 p-2">{{'LICENSE_STATUS_PAGE.TOTAL_HOUR' | translate}}</label>
                        <div class="summary-bg pl-4 pr-4 pt-3"
                            [style]="'background-color :'+totalAcquiredMinutestStatusHexColor">
                            <label
                                [style]="'color:'+totalAcquiredMinutesTextStatusHexColor">{{icLicenseSummaryV2Result.totalAcquiredMinutes/60|
                                number:'1.0-2'}}</label>
                        </div>
                    </div>
                </div>
                <div class="pt-3 d-flex flex-row justify-content-center">
                    <label class="title">{{'LICENSE_STATUS_PAGE.LCSD_REQUIRED' | translate}}</label>
                </div>
            </div>

            <div class="d-flex flex-column justify-content-center">

                <div class="pt-3 d-flex flex-row justify-content-start">
                    <label class="title">{{'LICENSE_STATUS_PAGE.LCS_CONDITION_ETHIC' | translate}}</label>
                </div>

                <div class="d-flex  flex-wrap">

                    <div *ngFor="let licenseDetail of licenseEthic"
                        class="p-0 m-1 card-detail d-flex flex-column align-content-between flex-wrap">

                        <div class="pr-2 pl-2 text-center flex-fill w-100">
                            <label class="detail-label-1">{{licenseDetail.categoryName}}</label>
                        </div>

                        <div class="p-0 detail-bg-2 text-center flex-fill w-100 equal-height"
                            [style]="'background-color :'+licenseDetail.hoursStatusHexColor">
                            <label class="detail-label-2">{{licenseDetail.acquiredMinutesByProfLink/60| number:'1.0-2'}}
                                {{'LICENSE_STATUS_PAGE.HR' | translate}}</label>
                        </div>

                    </div>
                </div>
            </div>

            <div class="d-flex flex-column justify-content-center">

                <div class="pt-3 d-flex flex-row justify-content-start">
                    <label class="title">{{'LICENSE_STATUS_PAGE.LCS_CONDITION_ESG' | translate}}</label>
                </div>

                <div class="d-flex  flex-wrap">

                    <div *ngFor="let licenseDetail of licenseEsg"
                        class="p-0 m-1 card-detail d-flex flex-column align-content-between flex-wrap">

                        <div class="pr-2 pl-2 text-center flex-fill w-100">
                            <label class="detail-label-1">{{licenseDetail.categoryName}}</label>
                        </div>

                        <div class="p-0 detail-bg-2 text-center flex-fill w-100 equal-height"
                            [style]="'background-color :'+licenseDetail.hoursStatusHexColor">
                            <label class="detail-label-2">{{licenseDetail.acquiredMinutesByProfLink/60| number:'1.0-2'}}
                                {{'LICENSE_STATUS_PAGE.HR' | translate}}</label>
                        </div>

                    </div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center">

                <div class="pt-3 d-flex flex-row justify-content-start">
                    <label class="title">{{'LICENSE_STATUS_PAGE.LCS_CONDITION_OTHERS' | translate}}</label>
                </div>

                <div class="d-flex flex-wrap">

                    <div *ngFor="let licenseDetail of licenseGeneral"
                        class="p-0 m-1 card-detail d-flex flex-column align-content-between flex-wrap">

                        <div class="pr-2 pl-2 text-center flex-fill w-100">
                            <label class="detail-label-1">{{licenseDetail.categoryName}}</label>
                        </div>

                        <div class="p-0 detail-bg-2 text-center flex-fill w-100 equal-height"
                            [style]="'background-color :'+licenseDetail.hoursStatusHexColor">
                            <label class="detail-label-2">{{licenseDetail.acquiredMinutesByProfLink/60| number:'1.0-2'}}
                                {{'LICENSE_STATUS_PAGE.HR' | translate}}</label>
                        </div>

                    </div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center">
                <div class="pt-3 d-flex flex-row justify-content-center">
                    <label class="title">{{'LICENSE_STATUS_PAGE.LCSD_TOTAL_REQUIRED' | translate}}</label>
                </div>
            </div>


            <div *ngIf="!isHavePrivilege">
                <div class="d-flex flex-column justify-content-center">
                    <div class="pt-3 d-flex flex-row justify-content-start">
                        <label class="title">{{'LICENSE_STATUS_PAGE.LCS_CONDITION_ETHIC' | translate}}</label>
                    </div>

                    <div class="flex-column card card-body card-custom p-0 m-0">

                        <div class="p-0 text-center">
                            <label class="title-required-1">{{'LICENSE_STATUS_PAGE.LCSD_TOTAL_REQUIRED' |
                                translate}}</label>
                        </div>

                        <div class="p-0 text-center">
                            <label class="title-required-2">{{icLicenseSummaryV2Result.totalRequiredEthicMinutes/60|
                                number:'1.0-2'}}</label>
                        </div>

                        <div class="p-0 text-center">
                            <label class="title-required-3">{{'PUBLISH.HOUR' | translate}}</label>
                        </div>
                        <!-- <div *ngIf="(icLicenseSummaryV2Result.totalRequiredEthicMinutes/60)==0?false:true"
                            class="p-0 text-center mb-3">
                            <button #EthicCourse class="btn btn-warning text-black text-center"
                                [routerLink]="['/search']"
                                [queryParams]="{categoryGroup: 'Ethic'}">ค้นหาหลักสูตร</button>
                        </div> -->
                    </div>
                </div>

                <div class="d-flex flex-column justify-content-center">
                    <div class="pt-3 d-flex flex-row justify-content-start">
                        <label class="title">{{'LICENSE_STATUS_PAGE.LCS_CONDITION_ESG' | translate}}</label>
                    </div>

                    <div class="flex-column card card-body card-custom p-0 m-0">

                        <div class="p-0 text-center">
                            <label class="title-required-1">{{'LICENSE_STATUS_PAGE.LCSD_REQUIRED_HOURS' |
                                translate}}</label>
                        </div>

                        <div class="p-0 text-center">
                            <label class="title-required-2">{{icLicenseSummaryV2Result.totalRequiredEsgMinutes/60|
                                number:'1.0-2'}}</label>
                        </div>

                        <div class="p-0 text-center">
                            <label class="title-required-3">{{'PUBLISH.HOUR' | translate}}</label>
                        </div>
                        <!-- <div *ngIf="(icLicenseSummaryV2Result.totalRequiredEsgMinutes)/60==0?false:true"
                            class="p-0 text-center mb-3">
                            <button #ESGCourse class="btn btn-warning text-black text-center" [routerLink]="['/search']"
                                [queryParams]="{categoryGroup: 'ESG'}">ค้นหาหลักสูตร</button>
                        </div> -->
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center">
                    <div class="pt-3 d-flex flex-row justify-content-start">
                        <label class="title">{{'LICENSE_STATUS_PAGE.LCS_CONDITION_OTHERS' | translate}}</label>
                    </div>

                    <div class="flex-column card card-body card-custom p-0 m-0">

                        <div class="p-0 text-center">
                            <label class="title-required-1">{{'LICENSE_STATUS_PAGE.LCSD_REQUIRED_HOURS' |
                                translate}}</label>
                        </div>

                        <div class="p-0 text-center">
                            <label class="title-required-2">{{icLicenseSummaryV2Result.totalRequiredGeneralMinutes/60|
                                number:'1.0-2'}}</label>
                        </div>

                        <div class="p-0 text-center">
                            <label class="title-required-3">{{'PUBLISH.HOUR' | translate}}</label>
                        </div>
                        <!-- <div *ngIf="(icLicenseSummaryV2Result.totalRequiredGeneralMinutes)/60==0?false:true"
                            class="p-0 text-center mb-3">
                            <button class="btn btn-warning text-black text-center" [routerLink]="['/search']"
                                [queryParams]="{categoryGroup: 'General'}">ค้นหาหลักสูตร</button>
                        </div> -->
                    </div>
                </div>

            </div>
            <div class="d-flex p-3 flex-row text-white justify-content-center" style="background-color: #27AE60;"
                *ngIf="isHavePrivilege">
                <span>{{privilegeDescription}}</span>

            </div>

            <div class="p-1 text-muted remark-text">
                {{'LICENSE_STATUS_PAGE.REMARK' | translate}}
            </div>
        </div>
    </div>
</div>