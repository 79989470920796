import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IcProfileModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import { DatePipe } from '@angular/common';
import { IcProfileControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';

import * as WSCall from "@app/utils/WSCall";
import { APIResultstring } from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-profile-information',
    templateUrl: './profile-information.component.html',
    styleUrls: ['./profile-information.component.scss', '../personal-profile.component.scss']
})
export class ProfileInformationComponent implements OnInit {

    constructor(
        private _datePipe: DatePipe,
        private translate: TranslateService
    ) {
        this.translate.use(localStorage.getItem('language'));
    }

    @Input() profile: IcProfileModel;
    @Output('edit') editForm: EventEmitter<string> = new EventEmitter();

    // firstName: string = '';
    // lastName: string = '';
    // middleName: string = '';
    profileLink: string = null;

    canEdit: boolean = false;

    displayName: string;
    language: string = 'th';

    ngOnInit(): void {
        this.language = localStorage.getItem('language');
        if (this.profile != null) {
            if (this.profile.nationality != null && this.profile.nationality.nationalityId == 1) {
                this.canEdit = true;
            }

            this.displayName = this.getDisplayName(this.profile);
            // this.firstName = this.profile.firstnameTh;
            // this.middleName = this.profile.middlenameTh;
            // this.lastName = this.profile.lastnameTh;

            // if (this.profile.firstnameTh == null || this.profile.firstnameTh.trim().length == 0) {
            //     this.firstName = this.profile.firstnameEn;
            // }

            // if (this.profile.middlenameTh == null || this.profile.middlenameTh.trim().length == 0) {
            //     this.middleName = this.profile.middlenameEn;
            // }

            // if (this.profile.lastnameTh == null || this.profile.lastnameTh.trim().length == 0) {
            //     this.lastName = this.profile.lastnameEn;
            // }

            if (this.profile.attachedProfileFile != null) {
                this.profileLink = this.profile.attachedProfileFile.attachmentLink;
            }



        }

    }

    getDisplayName(icProfile: IcProfileModel) {
        let displayName = '';

        if (this.language == 'th') {
            if (icProfile.firstnameTh != null && icProfile.firstnameTh != '') {
                let firstNameTh = icProfile.firstnameTh == null ? "" : icProfile.firstnameTh;
                let middlenameTh = icProfile.middlenameTh == null ? "" : icProfile.middlenameTh;
                let lastNameTh = icProfile.lastnameTh == null ? "" : icProfile.lastnameTh;

                displayName = firstNameTh + ' ' + middlenameTh + ' ' + lastNameTh;
            } else {
                let firstNameEn = icProfile.firstnameEn == null ? "" : icProfile.firstnameEn;
                let middlenameEn = icProfile.middlenameEn == null ? "" : icProfile.middlenameEn;
                let lastNameEn = icProfile.lastnameEn == null ? "" : icProfile.lastnameEn;

                displayName = firstNameEn + ' ' + middlenameEn + ' ' + lastNameEn;
            }
        } else if (this.language == 'en') {
            if (icProfile.firstnameEn != null && icProfile.firstnameEn != '') {
                let firstNameEn = icProfile.firstnameEn == null ? "" : icProfile.firstnameEn;
                let middlenameEn = icProfile.middlenameEn == null ? "" : icProfile.middlenameEn;
                let lastNameEn = icProfile.lastnameEn == null ? "" : icProfile.lastnameEn;

                displayName = firstNameEn + ' ' + middlenameEn + ' ' + lastNameEn;
            } else {
                let firstNameTh = icProfile.firstnameTh == null ? "" : icProfile.firstnameTh;
                let middlenameTh = icProfile.middlenameTh == null ? "" : icProfile.middlenameTh;
                let lastNameTh = icProfile.lastnameTh == null ? "" : icProfile.lastnameTh;

                displayName = firstNameTh + ' ' + middlenameTh + ' ' + lastNameTh;
            }

        } else {
            if (icProfile.firstnameTh != null && icProfile.firstnameTh != '') {
                let firstNameTh = icProfile.firstnameTh == null ? "" : icProfile.firstnameTh;
                let middlenameTh = icProfile.middlenameTh == null ? "" : icProfile.middlenameTh;
                let lastNameTh = icProfile.lastnameTh == null ? "" : icProfile.lastnameTh;

                displayName = firstNameTh + ' ' + middlenameTh + ' ' + lastNameTh;
            } else {
                let firstNameEn = icProfile.firstnameEn == null ? "" : icProfile.firstnameEn;
                let middlenameEn = icProfile.middlenameEn == null ? "" : icProfile.middlenameEn;
                let lastNameEn = icProfile.lastnameEn == null ? "" : icProfile.lastnameEn;

                displayName = firstNameEn + ' ' + middlenameEn + ' ' + lastNameEn;
            }
        }

        return displayName;
    }

    edit(): void {
        this.editForm.emit('information');
    }



}
