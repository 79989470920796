<div class="modal-body" style="padding-top: 0%;">
    <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">Search Approval No. by Name</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close('')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="d-flex" style="padding-top: 1rem; padding-bottom: 1rem;">
        <div class="col-8" style="padding: 0px;">
            <input class="form-control" type="text" style="width: 100%; height: 100%;"
                placeholder="Enter Name or Lastname at least 4 characters" [(ngModel)]="searchTerm" (input)="onSearchChange()" />
        </div>
        <div class="col-4" style=" padding-right: 0px;">
            <button type="button" style="width: 100%; color: white;" class="btn btn-block submit bg-primary"
                [disabled]="!canSearch" (click)="search()">Search</button>
        </div>
    </div>

    <div class="results-table-container">
        <table class="results-table">
            <thead>
                <tr>
                    <th>Approval No.</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let i of results" [class.selected]="i === selectedResult" (click)="selectResult(i)">
                    <td>{{ i.regis_sale_no }}</td>
                    <td>{{ i.givenname_en }}</td>
                    <td>{{ i.surname_en }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <button class="btn btn-primary" style="width: 100%; color: white;" [disabled]="!selectedResult"
        (click)="confirmSelection()">Select</button>
</div>