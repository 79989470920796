<div class="container col-sm-12 custom-container">
    <div class="col-sm-12 pt-4">
        <div class="col-sm-12 text-center">
            <label>{{'CONFIRM_OTP.T_SMS' | translate}}</label>
        </div>

        <div class="col-sm-12 text-center">
            <label>{{mobileNumber}}</label>
        </div>
        <div *ngIf="email" class="col-sm-12 text-center">
            <label>{{'CONFIRM_OTP.T_EMAIL' | translate}} {{email}}</label>
        </div>
        <div class="col-sm-12 text-center">
            <label>{{'CONFIRM_OTP.T_I_OTP' | translate}}</label>
        </div>
    </div>

    <div class="col-sm-12 pt-4 text-center">
        <div class="col-sm-12 col-xs-12 offset-md-3 col-md-6 offset-md-3">
            <input id="otpNumber" type="number" #otpInput (keyup)="validateInputAndSubmitOtp()">
        </div>
    </div>


    <div class="col-sm-12 pt-4">
        <div class="col-sm-12 text-center">
            <label>Ref Code : {{refCode}}</label>
        </div>

    </div>

    <div class="col-sm-12 text-center pt-4">
        <div class="col-sm-12 col-xs-12 offset-md-3 col-md-6 offset-md-3">
            <button type="button" class="btn btn-block btn-login" [disabled]="!canRequestOtp" (click)="requestOtp()">
                <label style="color: black;">{{'CONFIRM_OTP.B_RESEND_OTP' | translate}}</label>
            </button>
        </div>
    </div>

    <div class="col-sm-12 text-center pt-3">
        <div class="col-sm-12 text-center">
            <label>{{'CONFIRM_OTP.T_EXP' | translate}} {{counter | formatTime}} {{'CONFIRM_OTP.T_MIN' |
                translate}}</label>
        </div>
    </div>
</div>