import { AppState } from '@core/app-state';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangeMobileNumberRequest } from '@proflink/prof-link-web-ic-api-ts-angular/model/changeMobileNumberRequest';
import { ResultOTPModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/resultOTPModel';
import { RegisterICTemporaryAccountModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel';
import { APIResultResultOTPModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultResultOTPModel';
import * as WSCall from '@app/utils/WSCall';
import { AccountControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/accountController.service';
import { ChangeMobileNumberOTPRequest } from '@proflink/prof-link-web-ic-api-ts-angular/model/changeMobileNumberOTPRequest';
import { PublicMiscControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/publicMiscController.service';
import { ChangeMobileNumberResult } from '@proflink/prof-link-web-ic-api-ts-angular/model/changeMobileNumberResult';
import * as Utils from '@app/utils/Utils';
import { RegisterStateEnum } from '@public/register/register.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeMobileNumberOTPResult } from '@proflink/prof-link-web-ic-api-ts-angular/model/changeMobileNumberOTPResult';
import { APIResultChangeMobileNumberOTPResult } from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultChangeMobileNumberOTPResult';
import { ConsentVersionPublicControllerService } from '@proflink/prof-link-web-ic-api-ts-angular';
import { IcAgreeConsentLogPublicRequest } from '@proflink/prof-link-web-ic-api-ts-angular/model/icAgreeConsentLogPublicRequest';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-mobile-number',
  templateUrl: './change-mobile-number.component.html',
  styleUrls: ['./change-mobile-number.component.scss']
})
export class ChangeMobileNumberComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private _appState: AppState,
    private route: ActivatedRoute,
    private _accountControllerService: AccountControllerService,
    private _publicMiscControllerService: PublicMiscControllerService,
    private consentVersionPublicService: ConsentVersionPublicControllerService,
    private _translate: TranslateService
  ) {

  }

  resultOTPModel: ResultOTPModel;
  platform: string = '';

  titleError: string = 'ข้อมูลบางอย่างไม่ถูกต้อง';
  messageError: string = 'กรุณาตรวจสอบข้อมูลของท่าน แล้วลองใหม่อีกครั้ง';


  ngOnInit() {

    let _this = this;
    this.route.queryParams.subscribe(x => {
      this.platform = x['platform'];

      if (_this.platform != null && (_this.platform == 'mobile' || _this.platform == 'MOBILE')) {
        //@ts-ignore
        FLUTTER_CHANEL_CHANGE_MOBILE.postMessage("TEST_BROADCAST");
      }
    });

  }

  changeMobileNumberRequest: ChangeMobileNumberRequest;
  changeMobileNumberOTPRequest: ChangeMobileNumberOTPRequest;

  changeMobileNumberResult: ChangeMobileNumberResult;

  canSubmit: boolean = false;

  changeMobilePhoneNoState: ChangeMobilePhoneNoState = ChangeMobilePhoneNoState.INFO;

  onConfirmInfo() {
    this.changeMobilePhoneNoState = ChangeMobilePhoneNoState.CREATE_FORM;
  }
  onConfirmConsent(isAccepted: boolean) {
    if (isAccepted) {
      const param: IcAgreeConsentLogPublicRequest = {
        agree: 'Y',
        consentType: 'ATTACHMENT_DOC',
        username: this.changeMobileNumberRequest.idCard
      };

      this.consentVersionPublicService.agreeByConsentTypeUsingPOST(param).subscribe(x => {
        if (x.data) {
          this.changeMobilePhoneNoState = ChangeMobilePhoneNoState.CONFIRM;
        } else {
          this._translate.get('UTILS.TITLE_ERROR_CHANGE_MOBILE').subscribe((res: string) => {
            this.titleError = res;
          });
          this._translate.get('UTILS.DESCRIPTION_ERROR_CHANGE_MOBILE').subscribe((res: string) => {
            this.messageError = res;
          });
          Utils.error(this.messageError, this.titleError);
        }
      })

    } else {
      this.changeMobilePhoneNoState = ChangeMobilePhoneNoState.CREATE_FORM;
    }
  }

  onGotoConsent() {
    this.changeMobilePhoneNoState = ChangeMobilePhoneNoState.AGREE_CONSENT;
  }

  async onCreateForm(changeMobileNumberRequest: ChangeMobileNumberRequest) {
    this.changeMobileNumberRequest = changeMobileNumberRequest;
    let result: ChangeMobileNumberResult = await this.validateChangeMobilePhoneNumber(this.changeMobileNumberRequest);
    if (result.passValidate) {
      this.changeMobileNumberResult = result;
      this.onGotoConsent();
    } else {
      this._translate.get('UTILS.TITLE_ERROR_CHANGE_MOBILE').subscribe((res: string) => {
        this.titleError = res;
      });
      this._translate.get('UTILS.DESCRIPTION_ERROR_CHANGE_MOBILE').subscribe((res: string) => {
        this.messageError = res;
      });
      Utils.error(this.messageError, this.titleError);
    }
  }

  async onConfirmForm(changeMobileNumberOTPRequest: ChangeMobileNumberOTPRequest) {

    this.changeMobileNumberOTPRequest = changeMobileNumberOTPRequest;
    let registerICTemporaryAccountModel: RegisterICTemporaryAccountModel = {
      icIdentityNumber: changeMobileNumberOTPRequest.changeMobileNumberRequest.idCard,
      mobileNumber: changeMobileNumberOTPRequest.newMobilePhoneNumber,
      icId: this.changeMobileNumberResult.icProfileId,
      otpType: "CM"
    };

    let result: APIResultResultOTPModel = await this.generateOTPGeneral(registerICTemporaryAccountModel);
    if (result.resultCode == 'SUCCESS') {

      this.resultOTPModel = result.data;
      this.changeMobilePhoneNoState = ChangeMobilePhoneNoState.OTP;
    } else {
      Utils.error(result.errorMessage);
    }

  }

  async onVerifyOTPSuccess(token: string) {

    console.log(this.changeMobileNumberOTPRequest);
    let _this = this;
    this.changeMobileNumberOTPRequest.token = token;
    let resultChangeOTP: ChangeMobileNumberOTPResult = await this.requestOtpForChangeMobilePhoneNumber(this.changeMobileNumberOTPRequest);

    if (resultChangeOTP.passValidate) {
      this.changeMobilePhoneNoState = ChangeMobilePhoneNoState.CONFIRM;
      let _this = this;
      Utils.infoWithCallBack("ระบบ Prof.Link ได้รับเรื่องของท่านแล้ว และหากดำเนินการเสร็จจะแจ้งผลผ่านทาง sms และ  อีเมล", "ส่งคำขอเปลี่ยนแปลงเบอร์มือถือสำเร็จ", function () {

        if (_this.platform != null && (_this.platform == 'mobile' || _this.platform == 'MOBILE')) {

          //for develop flutter
          //@ts-ignore
          FLUTTER_CHANEL_CHANGE_MOBILE.postMessage("SUCCESS");
          this.changeMobilePhoneNoState = ChangeMobilePhoneNoState.INFO;
        } else {
          _this._router.navigate(["login"]);
        }
      });
    } else {
      Utils.error(resultChangeOTP.message);
    }

  }

  async requestOtpForChangeMobilePhoneNumber(changeMobileNumberOTPRequest: ChangeMobileNumberOTPRequest): Promise<ChangeMobileNumberOTPResult> {
    return WSCall.wsCall(
      () => {
        return this._publicMiscControllerService.requestOtpForChangeMobilePhoneNumberUsingPOST(changeMobileNumberOTPRequest);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async validateChangeMobilePhoneNumber(changeMobileNumberRequest: ChangeMobileNumberRequest): Promise<ChangeMobileNumberResult> {
    return WSCall.wsCall(
      () => {
        return this._publicMiscControllerService.validateChangeMobilePhoneNumberUsingPOST(changeMobileNumberRequest)
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async generateOTPGeneral(registerICTemporaryAccountModel: RegisterICTemporaryAccountModel): Promise<APIResultResultOTPModel> {
    return WSCall.wsCall(
      () => {
        return this._accountControllerService.generateOTPGeneralUsingPOST(registerICTemporaryAccountModel)
      },
      respBody => {
        return respBody;
      }
    );
  }



}


export type ChangeMobilePhoneNoState = 'INFO' | 'AGREE_CONSENT' | 'CREATE_FORM' | 'CONFIRM' | 'OTP'
export const ChangeMobilePhoneNoState = {

  INFO: 'INFO' as ChangeMobilePhoneNoState,
  AGREE_CONSENT: 'AGREE_CONSENT' as ChangeMobilePhoneNoState,
  CREATE_FORM: 'CREATE_FORM' as ChangeMobilePhoneNoState,
  CONFIRM: 'CONFIRM' as ChangeMobilePhoneNoState,
  OTP: 'OTP' as ChangeMobilePhoneNoState,

}
