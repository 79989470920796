<div [formGroup]="createPIN">

    <div class="col-sm-12 custom-input card">
        <div class="row card-body">
            <div class="col-sm-12 pt-3">
                <div class="col-sm-12">
                    <label>{{'PIN.T_PIN' | translate}}</label>
                </div>
                <div class="col-sm-12">
                    <input name="password" formControlName="password" maxlength="6" class="form-control input-password"
                        #password />
                    <div class="text-left" *ngIf="f.password.touched && f.password.invalid">
                        <div class="text-danger" *ngIf="f.password.errors.required">
                            {{'PIN.REQUIRE_PIN' | translate}}</div>
                        <div class="text-danger" *ngIf="f.password.errors.pattern">
                            {{'PIN.VERIFY_PIN' | translate}} </div>
                    </div>
                </div>
                <!-- <div class="col-sm-12">
                <input name="password"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="6" class="form-control input-password" inputmode="numeric" #password />
            </div> -->
            </div>

            <div class="col-sm-12 pt-3">
                <div class="col-sm-12">
                    <label>{{'PIN.T_CONFIRM_PIN' | translate}}</label>
                </div>
                <div class="col-sm-12">
                    <input maxlength="6" formControlName="confirmPassword" name="confirmPassword"
                        class="form-control input-password" name="confirmPassword" #confirmPassword />
                    <div class="text-left" *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid">
                        <div class="text-danger" *ngIf="f.confirmPassword.errors.required">
                            {{'PIN.REQUIRE_CONFIRM_PIN' | translate}}</div>
                        <div class="text-danger" *ngIf="f.confirmPassword.errors.pattern">
                            {{'PIN.VERIFY_PIN' | translate}} </div>
                    </div>
                </div>
                <!-- <div class="col-sm-12">
                <input type="password" 
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="6" name="confirmPassword" class="form-control input-password" name="confirmPassword"
                    #confirmPassword />
            </div> -->
            </div>

            <div class="col-sm-12 pt-4 text-center">
                <div class="col-sm-12">
                    <button type="button" [disabled]="!canSubmit" class="btn btn-block btn-login" (click)="createPin()">
                        <label style="color: black;">{{'BUTTON.NEXT' | translate}}</label>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>