<div class="container-fluid ">
    <div class="col-sm-12">

        <div class="col-sm-12 m-0 text-center">
            <p class="title-topic">ระบบได้ส่งลิงค์ยืนยันอีเมลไปที่</p>
        </div>

        <div class="p-2">

        </div>

        <div class="col-sm-12">
            <div class="m-4 custom-border text-center">
                <div class="col-sm-12 pt-3">
                    <p class="pt-2 pb-2">{{email}}</p>
                </div>

                <div class="col-sm-12">
                    <p>
                        กรุณยืนยันอีเมลภายใน 24 ชั่วโมง หากยืนยันอีเมลเรียบร้อยแล้วให้กดปุ่ม "เข้าสู่ระบบ" หรือ หากยังไม่ได้รับอีเมลให้กดปุ่ม "ส่งอีเมลอีกครั้ง"
                    </p>
                </div>
            </div>
        </div>

        <div class="p-4">

        </div>

        <div class="p-0 m-0 d-flex justify-content-center pt-3">
            <div class="p-2 d-flex d-inline-block">
                <div class="">
                    <button (click)="resendEmail()" class="btn btn-default btn-email-2 pl-3 pr-3">ส่งอีเมลอีกครั้ง</button>
                </div>

                <div class="p-3"></div>

                <div class="">
                    <button (click)="redirectToLogin()" class="btn btn-primary btn-email pl-3 pr-3">เข้าสู่ระบบ</button>
                </div>
            </div>
        </div>
    </div>
</div>
