<app-header-with-banner></app-header-with-banner>
<div class="box-container">
    <div class="box">
        <div class="pt-3 pt-sm-5">
            <div class="form-group pt-sm-5 pb-sm-5">
                <div class="custom-input">
                    <app-email-validate-form *ngIf="emailState == 'EMAIL_FORM'"
                         [lastSentEmail]="email"
                         [lastSentEmailDate]="lastSentEmailDate"
                        (completeState)="onConfirmEmail($event)"></app-email-validate-form>
                    <app-email-validate-confirm *ngIf="emailState == 'EMAIL_CONFIRM'"
                        [email]="email"></app-email-validate-confirm>
                    <app-email-validate-info (completeState)="onCompleteInfo($event)" *ngIf="emailState == 'EMAIL_INFO'"></app-email-validate-info>
                </div>
            </div>
        </div>
    </div>
</div>