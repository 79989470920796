<app-header-with-back class="header" [title]="title" [path]="'course-activity'"></app-header-with-back>
<div class="box-container">
    <div class="box">
        <div *ngIf="!isCreate" style="display: flex; font-weight: bold;">
            <h3 *ngIf="statusFlow == 'APPROVED'" style=" color: #03A439; padding-left: 1rem;">{{statusMessage}}
                :
                {{individualCode}} on {{approveDate | date:"dd/MM/yyyy HH:mm:ss"}}</h3>
            <h3 *ngIf="statusFlow == 'REQUESTED' || statusFlow == 'PENDING'"
                style="color: #4176C7; padding-left: 1rem;">
                {{statusMessage}}</h3>
            <div *ngIf="statusFlow == 'REJECTED'"
                style="display: flex; align-items: center; gap: 1rem; padding-left: 1rem;">
                <h3 style="color: red; font-weight: bold;">
                    {{statusMessage}}
                </h3>
                <h4 style="color: red;">
                    on {{rejectTime}}
                </h4>
                <h4>
                    [{{reason}}]
                </h4>
            </div>

        </div>
        <h4 style="background-color: #E3EBF3; font-weight: bold; padding: 0.5rem 1rem;">Activity Detail</h4>

        <div class="card card-body" style="margin: 1rem;" *ngFor="let activity of activityForm.controls; let i = index">
            <form [formGroup]="activityForm.controls[i]" class="activity-form">
                <div class="form-group inline-group">
                    <label for="activityType">
                        <ng-container *ngIf="!activity.get('activityType').disabled">
                            {{ i + 1 }}.
                        </ng-container>
                        Activity Type :
                        <span *ngIf="!activity.get('activityType').disabled" class="text-danger">*</span>
                    </label>
                    <button *ngIf="i !=0 " type="button" class="close-button" (click)="removeActivity(i)"></button>
                    <div *ngIf="activity.get('activityType').disabled">
                        {{ activity.get('activityTypeMessage').value }}
                    </div>
                    <div *ngIf="!activity.get('activityType').disabled" class="radio-group">
                        <input type="radio" formControlName="activityType" value="I" />
                        <span class="radio-text">Speaker</span>
                        <input type="radio" formControlName="activityType" value="P" />
                        <span class="radio-text">Lecturer</span>
                        <input type="radio" formControlName="activityType" value="A" />
                        <span class="radio-text">Class Attendee</span>
                        <input type="radio" formControlName="activityType" value="E" />
                        <span class="radio-text">e-Learning Attendee</span>
                    </div>
                    <!-- <div *ngIf="activityForm.controls[i].type?.invalid && activityForm.controls[i].type?.touched"
                        class="alert alert-danger">
                        Activity Type is required.
                    </div> -->
                </div>

                <div class="form-group inline-group">
                    <label for="courseName">Course Name / Topic : <span *ngIf="!activity.get('courseName').disabled"
                            class="text-danger">*</span></label>
                    <div *ngIf="activity.get('courseName').disabled">
                        {{ activity.get('courseName').value }}
                    </div>
                    <input *ngIf="!activity.get('courseName').disabled" type="text" formControlName="courseName"
                        required />
                </div>

                <div class="form-group inline-group">
                    <label for="institute">Institute : <span *ngIf="!activity.get('institute').disabled"
                            class="text-danger">*</span></label>
                    <div *ngIf="activity.get('institute').disabled">
                        {{ activity.get('institute').value }}
                    </div>
                    <input *ngIf="!activity.get('institute').disabled" type="text" formControlName="institute"
                        required />
                </div>

                <div class="form-group inline-group">
                    <label for="lectureDate">Lecture Date : <span *ngIf="!activity.get('lectureDateFrom').disabled"
                            class="text-danger">*</span></label>
                    <div *ngIf="activity.get('lectureDateFrom').disabled">
                        {{ activity.get('lectureDateFromMessage').value }}
                    </div>
                    <input *ngIf="!activity.get('lectureDateFrom').disabled" type="date"
                        formControlName="lectureDateFrom" required />
                    <span>to</span>
                    <div *ngIf="activity.get('lectureDateTo').disabled">
                        {{ activity.get('lectureDateToMessage').value }}
                    </div>
                    <input *ngIf="!activity.get('lectureDateTo').disabled" type="date" formControlName="lectureDateTo"
                        required />

                </div>
                <div class="form-group inline-group">
                    <label for=""></label>
                    <!-- Error message -->
                    <div *ngIf="activity.errors?.dateRangeInvalid" class="text-danger">
                        Lecture Date From must not be greater than Lecture Date To.
                    </div>
                </div>

                <div class="form-group inline-group">
                    <label for="hoursLecture">Hours of Lecture : <span *ngIf="!activity.get('hoursLecture').disabled"
                            class="text-danger">*</span></label>
                    <div *ngIf="activity.get('hoursLecture').disabled">
                        {{ activity.get('hoursLecture').value }}
                    </div>
                    <select *ngIf="!activity.get('hoursLecture').disabled" formControlName="hoursLecture" required>
                        <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                    </select> <a style="margin-right: 1.5rem;">hrs</a>
                    <div *ngIf="activity.get('minutesLecture').disabled">
                        {{ activity.get('minutesLecture').value }}
                    </div>
                    <select *ngIf="!activity.get('minutesLecture').disabled" formControlName="minutesLecture" required>
                        <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                    </select> mins
                </div>

                <div class="form-group inline-group">
                    <label for="topicDetail">Topic : <span *ngIf="!activity.get('topicDetail').disabled"
                            class="text-danger">*</span></label>
                    <div *ngIf="activity.get('topicDetail').disabled">
                        {{ activity.get('topicDetail').value }}
                    </div>
                    <textarea *ngIf="!activity.get('topicDetail').disabled" formControlName="topicDetail"
                        required></textarea>
                </div>

                <div class="form-group inline-group">
                    <label for="areaOfKnowledge">Area of Knowledge : <span
                            *ngIf="!activity.get('areaOfKnowledge').disabled" class="text-danger">*</span></label>
                    <div *ngIf="activity.get('areaOfKnowledge').disabled">
                        {{ activity.get('areaOfKnowledgeMessage').value }}
                    </div>
                    <ng-select style="flex: 1;" *ngIf="!activity.get('areaOfKnowledge').disabled"
                        [items]="listOfCategory" bindLabel="categoryName" bindValue="categoryCode"
                        formControlName="areaOfKnowledge" required>
                    </ng-select>
                </div>

                <div class="form-group inline-group">
                    <label for="credit">Credit : <span *ngIf="!activity.get('hoursCredit').disabled"
                            class="text-danger">*</span></label>
                    <div *ngIf="activity.get('hoursCredit').disabled">
                        {{ activity.get('hoursCredit').value }}
                    </div>
                    <select *ngIf="!activity.get('hoursCredit').disabled" formControlName="hoursCredit" required>
                        <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                    </select><a style="margin-right: 1.5rem;">hrs</a>
                    <div *ngIf="activity.get('minutesCredit').disabled">
                        {{ activity.get('minutesCredit').value }}
                    </div>
                    <select *ngIf="!activity.get('minutesCredit').disabled" formControlName="minutesCredit" required>
                        <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                    </select>mins
                </div>


                <div *ngIf="!activity.get('hoursCredit').disabled" class="form-group inline-group">
                    <label>Proof of participation in the activity : <span *ngIf="!activity.get('hoursCredit').disabled"
                            class="text-danger">*</span></label>
                    <div class="col" style="padding: 0;">
                        <label class="btn-import">
                            <img src="/assets/icons/upload.svg" />
                            <input type="file" style="display: none;" (change)="onFileSelected($event, i)"
                                accept=".pdf,.doc,.xls,.ppt,.jpg,.gif,.docx,.xlsx,.pptx" multiple="multiple" required />
                            Import
                        </label><br>
                        <a>Maximun Size 15 MB/File ( .pdf, .doc, .xls, .ppt,
                            .jpg, .gif, .docx, .xlsx, .pptx )</a>
                    </div>
                </div>
                <div *ngIf="activity.get('proofOfParticipation').value.length > 0" class="form-group inline-group">
                    <label *ngIf="activity.get('proofOfParticipation').disabled" for="proofOfParticipation">Proof of
                        participation in the activity :
                        <span *ngIf="!activity.get('proofOfParticipation').disabled"
                            class="text-danger">*</span></label>
                    <label *ngIf="!activity.get('proofOfParticipation').disabled"></label>
                    <div class="box-file">
                        <div *ngFor="let file of activity.get('proofOfParticipation').value; let fileIndex = index"
                            style="position: relative;">
                            <a [href]="file.attachmentLink" target="_blank"
                                style="text-decoration: none; color: inherit;">
                                <div style="display: flex; align-items: center; position: relative; margin-top: 5px;">

                                    <img src="/assets/icons/file.svg" width="40rem" />
                                    <span class="file-upload">
                                        {{ file.fileName }}
                                    </span>
                                </div>
                            </a>

                            <span *ngIf="!activity.get('proofOfParticipation').disabled" class="remove-button"
                                (click)="removeFile(i,fileIndex)">
                                &times;
                            </span>
                        </div>

                    </div>
                </div>

                <div class="form-group inline-group" style="align-items: start; align-content: start;">
                    <label for="coordinator">Coordinator : <span *ngIf="!activity.get('coordinatorName').disabled"
                            class="text-danger">*</span></label>
                    <div class="col" style="padding: 0;">
                        <div class="form-group inline-group">
                            <label for="coordinatorName">Name : <span *ngIf="!activity.get('coordinatorName').disabled"
                                    class="text-danger">*</span></label>
                            <div *ngIf="activity.get('coordinatorName').disabled">
                                {{ activity.get('coordinatorName').value }}
                            </div>
                            <input *ngIf="!activity.get('coordinatorName').disabled" type="text"
                                formControlName="coordinatorName" required />
                        </div>
                        <div class="form-group inline-group">
                            <label for="coordinatorMobileNumber">Mobile Number : <span
                                    *ngIf="!activity.get('coordinatorMobileNumber').disabled"
                                    class="text-danger">*</span></label>
                            <div *ngIf="activity.get('coordinatorMobileNumber').disabled">
                                {{ activity.get('coordinatorMobileNumber').value }}
                            </div>
                            <input *ngIf="!activity.get('coordinatorMobileNumber').disabled" type="text"
                                formControlName="coordinatorMobileNumber" required />
                        </div>

                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="isCreate" class="form-group text-start" style="margin-left: 1rem;">
            <button type="button" class="btn btn-info button-pending" (click)="addActivity()">+ Add Activity</button>
        </div>
        <div *ngIf="statusFlow == 'REJECTED'" class="form-group text-start button-pending" style="margin-left: 1rem;">
            <button type="button" class="btn btn-outline-danger" (click)="deleteActivity()">x Delete</button>
        </div>

        <div *ngIf="isCreate || requestEditStatus == 'ALLOWED'" class="form-group pt-3 pb-3 text-center">
            <button type="button" class="btn btn-secondary button-pending" (click)="resetActivities()">
                <img src="/assets/icons/reset.svg" />Reset
            </button>
            <button type="submit" class="btn btn-primary button-pending" style="color: white;"
                (click)="saveActivities()">
                <img src="/assets/icons/check.svg" />Save
            </button>
        </div>
        <div *ngIf="requestEditStatus == null && (statusFlow == 'REQUESTED' || statusFlow == 'PENDING')"
            class="form-group pt-3 pb-3 text-center">
            <button type="button" class="btn btn-primary button-pending" (click)="request()">
                <i class="far fa-edit button-ic"></i>
                Request For Edit
            </button>
        </div>

        <div class="card card-body" style="margin: 1rem;" *ngIf="!isCreate">
            <div class="history-header" (click)="toggleTable()">
                <span class="history-title">
                    <img *ngIf="showTable" src="/assets/icons/double-down.svg">
                    <img *ngIf="!showTable" src="/assets/icons/double-down.svg" style="transform: rotate(270deg);">
                    History</span>
            </div>
            <table class="history-table" *ngIf="showTable">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Actor Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let history of historyActionCourse">
                        <td>{{ history.createdDate | date:"dd/MM/yyyy HH:mm:ss"}}</td>
                        <td>{{ history.createdBy }}</td>
                        <td>
                            {{ history.action }}
                            <span *ngIf="history.action === 'Rejected'"> : {{ history.reason }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</div>