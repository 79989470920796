<div class="container">
    <div class="col-sm-12 pt-4">
        <div class="col-sm-12 p-1 border-custom">
            <div class="col-sm-12 text-center pt-2 m-">
                <label class="header-info">{{'CHANGE_MOBILE_NUMBER.T_HEADER' | translate}}</label>
            </div>
            <div class="col-sm-12 pt-1">
                <div class="col-sm-12">
                    <label class="body-info">
                        {{'CHANGE_MOBILE_NUMBER.DESCRITION_1' | translate}}
                    </label>
                    <label class="body-info">
                        {{'CHANGE_MOBILE_NUMBER.DESCRITION_2' | translate}}
                    </label>
                </div>
            </div>
            <div class="col-sm-12 pt-4">
                <div class="col-sm-12">
                    <label class="body-info"> {{'CHANGE_MOBILE_NUMBER.DESCRITION_3' | translate}}</label>
                </div>

                <div class="col-sm-12">
                    <img src="../../../../assets/image/dc_1.png" width="200px" />
                </div>
            </div>

            <div class="col-sm-12 pt-4">
                <div class="col-sm-12">
                    <label class="body-info"> {{'CHANGE_MOBILE_NUMBER.DESCRITION_4' | translate}}</label>
                </div>

                <div class="col-sm-12">
                    <img src="../../../../assets/image/verify05.jpeg" width="200px" />
                </div>
            </div>
        </div>

        <div class="col-sm-12 text-center p-2">
            <button class="btn btn-primary" (click)="nextStage()">{{'BUTTON.CONTINUE' |
                translate}}</button>
        </div>

    </div>


</div>