<ul class="nav navbar-light bg-light "
    [ngClass]="{ 'hold-result': showDataCheckInCheckout && holdResult, 'success': showDataCheckInCheckout && !holdResult}">

    <li class="nav-item" *ngIf="platform != 'mobile'" (click)="backToList()">
        <div class="nav-link">
            <i class="fas fa-arrow-left icon-header"></i>
        </div>
    </li>
    <li class="nav-item">
        <div class="nav-link">
            <p class="m-0 title-header">{{ showDataCheckInCheckout ? status : "รายละเอียดหลักสูตร"}}</p>
        </div>
    </li>
    <li class="nav-item">
        <div class="nav-link d-none d-sm-block"></div>
    </li>
</ul>

<div class="box-container">
    <div class="box-no-border">
        <div class="text-center">
            <div
                *ngIf="session != null && session.cover != null && session.cover.downloadLink != null;else other_content">
                <img [src]="session.cover.downloadLink" class="img">
            </div>
            <ng-template #other_content>
                <img src="../../../../assets/image/session_cover_placeholder.png" class="img">
            </ng-template>
        </div>
        <div class="d-flex justify-content-center align-items-center img-detail">
            <div class="pl-4 py-2 text-warning">
                <div>{{displayTrainingDate}}</div>
                <div>{{location}}</div>
            </div>
            <div class="p-2 flex-fill text-right">
                <button *ngIf="holdResult && showDataCheckInCheckout" class="btn btn-warning text-black text-center"
                    [routerLink]="['/consent/ATTACHMENT_DOC']"
                    [queryParams]="{acceptRedirectUrl: '/attachment-document/' + session.sessionId}">{{'MY_COURSE.ATTACH_DOCUMENT'
                    | translate}}</button>
            </div>
        </div>
    </div>
</div>