import { Component, Input, OnInit, ViewChild, Output, EventEmitter, AfterViewInit, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { b64toBlob } from '@app/utils/Utils';
import { Location } from '@angular/common';
import { ConsentVersionControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/consentVersionController.service';
import { ConsentVersionPublicControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/consentVersionPublicController.service';
import { IcAgreeConsentLogPublicRequest } from '@proflink/prof-link-web-ic-api-ts-angular/model/icAgreeConsentLogPublicRequest';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-consent-by-type',
    templateUrl: './consent-by-type.component.html',
    styleUrls: ['./consent-by-type.component.scss']
})
export class ConsentByTypeComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad: { pdfSrc: Blob; refresh: () => void; };
    @Input() isPublic: boolean = true;
    textHtml: string = "";

    isAcceptConsentForm: FormGroup = new FormGroup({
        isAcceptConsent: new FormControl({ value: null, disabled: true }),
    });

    get isAcceptConsent(): FormControl {
        return this.isAcceptConsentForm.controls.isAcceptConsent as FormControl;
    }

    constructor(private router: Router,
        private location: Location,
        private activatedRoute: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private consentVersionPublicService: ConsentVersionPublicControllerService,
        private _consentVersionPublicControllerService: ConsentVersionPublicControllerService,
        private consentVersionService: ConsentVersionControllerService,
        private _translate: TranslateService) {
        this._translate.use(localStorage.getItem('language'));
    }

    acceptRedirectUrl = '';
    @Input() consentType = '';
    @Output() nextStateComplete: EventEmitter<Boolean> = new EventEmitter();
    pdfSrc: any;


    ngOnInit(): void {
        if (this.isPublic) {
            this.activatedRoute.queryParams.subscribe(params => {
                this.acceptRedirectUrl = params.acceptRedirectUrl;
            });
            this.consentType = this.activatedRoute.snapshot.params.consentType;
            // this.consentVersionService.getLatestByTypeConsentVersionBase64PDFUsingGET(this.consentType).subscribe(data => {
            //     this.pdfViewerAutoLoad.pdfSrc = b64toBlob(data.data, 'application/pdf'); // pdfSrc can be Blob or Uint8Array
            //     this.pdfViewerAutoLoad.refresh();
            // });
        } else {
            this.consentType = 'ATTACHMENT_DOC'
            // this.consentVersionPublicService.getLatestByTypeConsentVersionBase64PDFUsingGET1(this.consentType).subscribe(data => {
            //     this.pdfViewerAutoLoad.pdfSrc = b64toBlob(data.data, 'application/pdf'); // pdfSrc can be Blob or Uint8Array
            //     this.pdfViewerAutoLoad.refresh();
            // });
        }

        this._consentVersionPublicControllerService.getLatestConsentVersionAppHtmlUsingGET(this.consentType).subscribe(
            data => {
                if (data.resultCode === 'SUCCESS' && data.data != null && data.data != '') {
                    this.textHtml = data.data;
                } else {
                    console.log(data);
                }
            }, error => {
                console.log(error);
            }, () => console.log('Process Completed'));

    }

    ngAfterViewInit(): void {

    }

    accept(): void {
        if (this.isPublic) {
            this.router.navigate([this.acceptRedirectUrl]);
        } else {
            this.nextStateComplete.emit(true);
        }

    }

    notAccept(): void {
        if (this.isPublic) {
            this.location.back();
        } else {
            this.nextStateComplete.emit(false);
        }

    }

    ngOnDestroy(): void {

    }
}
