import { ChangeMobileNumberComponent } from './public/change-mobile-number/change-mobile-number.component';
import { BannerELearningComponent } from './protected/e-learning/banner-e-learning.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@public/login/login.component';
import { OTPComponent } from "@shared/otp/otp.component";
import { ELearningComponent } from './protected/e-learning/e-learning.component';
import { HomeComponent } from './protected/home/home.component';
import { NewsAndActivityComponent } from './protected/news-and-activity/news-and-activity.component';
import { LayoutComponent } from './protected/layout/layout.component';
import { MainMenuComponent } from './protected/main-menu/main-menu.component';
import { MoreComponent } from './protected/more/more.component';
import { MyCourseComponent } from './protected/my-course/my-course.component';
import { SearchComponent } from './protected/search/search.component';
import { ProfileComponent } from './protected/profile/profile.component';
import { MoreMenuListComponent } from './protected/more/more-menu-list/more-menu-list.component';
import { PrivacySettingComponent } from './protected/more/more-menu-list/privacy-setting/privacy-setting.component';
import { ChangePinComponent } from './protected/more/more-menu-list/privacy-setting/change-pin/change-pin.component';
import { LanguageSettingComponent } from './protected/more/more-menu-list/language-setting/language-setting.component';
import { ContactUsComponent } from './protected/more/more-menu-list/contact-us/contact-us.component';
import { AboutUsComponent } from './protected/more/more-menu-list/about-us/about-us.component';
import { ConditionAndAgreementComponent } from './protected/more/more-menu-list/condition-and-agreement/condition-and-agreement.component';
import { ForgotPasswordComponent } from "@public/forgot-password/forgot-password.component";
import { RegisterComponent } from "@public/register/register.component";
import { InstructionalShareComponent } from "@shared/instructional-share/instructional-share.component";
import { NewsFeedComponent } from './protected/home/news-feed/news-feed.component';
import { NotificationComponent } from "@protected/notification/notification.component";
import { NotificationDetailComponent } from "@protected/notification/notification-detail/notification-detail.component";
import { UpcomingCourseSearchComponent } from "@protected/home/upcoming-course/upcoming-course-search/upcoming-course-search.component";
import { MyCourseDetailShareComponent } from "@shared/my-course-share/my-course-detail-share/my-course-detail-share.component";
import { ProfileMenuListComponent } from '@protected/profile/profile-menu-list/profile-menu-list.component';
import { DigitalPassportComponent } from '@protected/profile/profile-menu-list/digital-passport/digital-passport.component';
import { CheckinCheckoutOnlineComponent } from '@protected/profile/profile-menu-list/checkin-checkout-online/checkin-checkout-online.component';
import { LicenseStatusComponent } from './protected/profile/profile-menu-list/license-status/license-status.component';
import { ConsentByTypeComponent } from '@shared/consent-by-type/consent-by-type.component';
import { AttachmentDocumentComponent } from '@protected/my-course/my-course-detail/attachment-document/attachment-document.component';
import { CancellationAndPrivacyComponent } from './protected/more/more-menu-list/privacy-setting/cancellation-and-privacy/cancellation-and-privacy.component';
import { PersonalProfileComponent } from '@protected/profile/profile-menu-list/personal-profile/personal-profile.component';
import { LicenseStatusDetailComponent } from '@protected/profile/profile-menu-list/license-status-detail/license-status-detail.component';
import { ELearningListComponent } from '@protected/e-learning/e-learning-list/e-learning-list.component';
import { LoginConsentComponent } from '@public/login-consent/login-consent.component';
import { EmailValidateComponent } from '@protected/email-validate/email-validate.component';
import { ReportProblemComponent } from '@public/report-problem/report-problem.component';
import { InstructionalLicenseShareComponent } from '@shared/instructional-license-share/instructional-license-share.component';
import { ShareRegisterConsentComponent } from '@public/share-register-consent/share-register-consent.component';
import { ELearningResultComponent } from './protected/e-learning/e-learning-result/e-learning-result.component';
import { RegisterForeignerFormComponent } from './public/register/register-foreigner-form/register-foreigner-form.component';
import { RegisterForeignerFormResultComponent } from './public/register/register-foreigner-form-result/register-foreigner-form-result.component';
import { CourseActivitityComponent } from './protected/course-activitity/course-activitity.component';
import { CreateCourseActivityComponent } from './protected/course-activitity/create-course-activity/create-course-activity.component';
import { PublicCourseComponent } from './public/public-course/public-course.component';


const routes: Routes = [

  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { title: '' } },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: '' } },
  { path: 'register', component: RegisterComponent, data: { title: '' } },
  { path: 'change-mobile-number', component: ChangeMobileNumberComponent, data: { title: '' } },
  { path: 'public-course',component: PublicCourseComponent, data: { title: '' }},
  // { path: 'ic-consent', component: IcConsentComponent, data: { title: '' } },
  // { path: 'register-form', component: RegisterFormComponent, data: { title: '' } },
  // { path: 'register-form-result', component: RegisterFormResultComponent, data: { title: '' } },
  // { path: 'register-form-create', component: RegisterFormCreateComponent, data: { title: '' } },
  // { path: 'create-pin-share', component: CreatePinShareComponent, data: { title: '' } },
  { path: 'instructional-share', component: InstructionalShareComponent, data: { title: '' } },
  { path: 'instructional-license-share', component: InstructionalLicenseShareComponent, data: { title: '' } },
  // { path: 'news-feed', component: NewsFeedComponent, data: { title: '' } },
  { path: 'otp', component: OTPComponent, data: { title: '' } },
  { path: 'consent/:consentType', component: ConsentByTypeComponent },
  { path: 'attachment-document/:sessionId', component: AttachmentDocumentComponent },
  { path: 'upcoming-course-search', component: UpcomingCourseSearchComponent, data: { title: '' } },
  { path: 'login-consent', component: LoginConsentComponent, data: { title: '' } },
  { path: 'share-register-consent', component: ShareRegisterConsentComponent, data: { title: '' } },
  { path: 'register-foreigner-form', component: RegisterForeignerFormComponent, data: { title: 'Create a Prof. Link Profile' } },
  { path: 'register-foreigner-form-result', component: RegisterForeignerFormResultComponent, data: { title: 'Create a Prof. Link Profile' } },


  //for test
  { path: 'report-problem', component: ReportProblemComponent },
  { path: 'test-elearning', component: ELearningComponent, data: { title: '' } },
  { path: 'test-elearning-detail', component: ELearningListComponent, data: { title: '' } },
  { path: 'upcoming-course-search', component: UpcomingCourseSearchComponent, data: { title: '' } },
  { path: 'change-mobile-number', component: ChangeMobileNumberComponent, data: { title: '' } },
  { path: 'my-course-detail-share/:sessionId/:flagShowData/:pathBack', component: MyCourseDetailShareComponent },
  { path: 'email-validate', component: EmailValidateComponent },
  {
    path: '', component: LayoutComponent,
    children: [
      { path: '', redirectTo: '', pathMatch: 'full' },
      {
        path: '', component: MainMenuComponent, children: [
          { path: '', redirectTo: 'home', pathMatch: 'full' },
          { path: 'home', component: HomeComponent },
          { path: 'search', component: SearchComponent },
          { path: 'course-activity', component: CourseActivitityComponent },
          { path: 'mycourse', component: MyCourseComponent },
          { path: 'elearning', component: ELearningComponent },
          { path: 'bannerelearning', component: BannerELearningComponent },
          {
            path: 'more', component: MoreComponent,
            children: [
              { path: '', component: MoreMenuListComponent },
            ]
          }
        ]
      },
      { path: 'new-and-activity', component: NewsAndActivityComponent },
      { path: 'notification', component: NotificationComponent },
      { path: 'notification-detail/:noticeId/:icNoticeId', component: NotificationDetailComponent },
      { path: 'news-feed', component: NewsFeedComponent },
      {
        path: 'profile', component: ProfileComponent,
        children: [
          { path: '', component: ProfileMenuListComponent }
        ]
      },
      { path: 'profile/personal/:tabSelectedId', component: PersonalProfileComponent },
      { path: 'profile/digital-passport', component: DigitalPassportComponent },
      { path: 'profile/checkin-checkout-online', component: CheckinCheckoutOnlineComponent },
      { path: 'profile/license-status', component: LicenseStatusComponent },
      { path: 'profile/license-status-detail', component: LicenseStatusDetailComponent },
      { path: 'more/privacy-setting', component: PrivacySettingComponent },
      { path: 'more/privacy-setting/change-pin', component: ChangePinComponent },
      { path: 'more/privacy-setting/cancellation-and-privacy', component: CancellationAndPrivacyComponent },
      { path: 'more/language-setting', component: LanguageSettingComponent },
      { path: 'more/contact-us', component: ContactUsComponent },
      { path: 'more/about-us', component: AboutUsComponent },
      { path: 'more/condition-and-agreement', component: ConditionAndAgreementComponent },
      { path: 'elearning-result', component: ELearningResultComponent },
      { path: 'course-activity/create/:individualId', component: CreateCourseActivityComponent },
    ]
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
