import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ReSendEmailRequest} from "@proflink/prof-link-web-ic-api-ts-angular/model/reSendEmailRequest";

@Component({
  selector: 'app-email-validate-info',
  templateUrl: './email-validate-info.component.html',
  styleUrls: ['./email-validate-info.component.scss']
})
export class EmailValidateInfoComponent implements OnInit {

  constructor() { }

  @Output('completeState')
  completeState: EventEmitter<boolean> = new EventEmitter();

  ngOnInit(): void {
  }

  confirmInformation(){

    this.completeState.emit(true);

  }

}
