<div class="container">
    <div class="col-sm-12 pt-4 ">
        <div [formGroup]="icForm" class="form-group">

            <div class="col-sm-12 border-custom m-2">
                <div class="col-sm-6 offset-md-3 col-xs-12 pt-3">
                    <div class="col-xs-12">
                        <label class="change-mobile-info">{{'CHANGE_MOBILE_NUMBER.TITLE' | translate}}</label>
                    </div>

                </div>
                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info required">{{'CHANGE_MOBILE_NUMBER.T_NAME' | translate}}</label>
                    </div>

                    <div class="col-xs-12 text-left">
                        <input class="form-control" formControlName="firstName" />
                        <div *ngIf="f.firstName.touched && f.firstName.invalid">
                            <div class="text-danger" *ngIf="f.firstName.errors.required">
                                {{'CHANGE_MOBILE_NUMBER.REQUIRE_NAME' | translate}}</div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info">{{'CHANGE_MOBILE_NUMBER.T_MIDDLE_NAME' | translate}}</label>
                    </div>

                    <div class="col-xs-12 text-left">
                        <input class="form-control" formControlName="middleName" />
                    </div>
                </div>

                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info required">{{'CHANGE_MOBILE_NUMBER.T_LAST_NAME' |
                            translate}}</label>
                    </div>

                    <div class="col-xs-12 text-left">
                        <input class="form-control" formControlName="lastName" />
                        <div *ngIf="f.lastName.touched && f.lastName.invalid">
                            <div class="text-danger" *ngIf="f.lastName.errors.required">
                                {{'CHANGE_MOBILE_NUMBER.REQUIRE_LAST_NAME' | translate}}</div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info required">{{'CHANGE_MOBILE_NUMBER.T_ID_CARD' |
                            translate}}</label>
                    </div>

                    <div class="col-xs-12 text-left">
                        <input class="form-control" formControlName="idCard" maxlength="13" />
                        <div *ngIf="f.idCard.touched && f.idCard.invalid">
                            <div class="text-danger" *ngIf="f.idCard.errors.required">
                                {{'CHANGE_MOBILE_NUMBER.REQUIRE_ID_CARD' | translate}}
                            </div>
                            <div class="text-danger" *ngIf="f.idCard.errors.pattern">
                                {{'CHANGE_MOBILE_NUMBER.VERIFY_PATTERN_ID_CARD' | translate}}</div>
                            <div class="text-danger" *ngIf="f.idCard.errors.formatCorrect">
                                {{'CHANGE_MOBILE_NUMBER.VERIFY_ID_CARD' | translate}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info required">{{'CHANGE_MOBILE_NUMBER.T_CURRENT_MOBILE' |
                            translate}}</label>
                    </div>

                    <div class="col-xs-12 text-left">
                        <input type="text" class="form-control required" formControlName="mobilePhoneNumber"
                            maxlength="10">
                        <div *ngIf="f.mobilePhoneNumber.touched && f.mobilePhoneNumber.invalid">
                            <div class="text-danger" *ngIf="f.mobilePhoneNumber.errors.required">
                                {{'CHANGE_MOBILE_NUMBER.REQUIRE_CURRENT_MOBILE' | translate}}</div>
                            <div class="text-danger" *ngIf="f.mobilePhoneNumber.errors.pattern">
                                {{'CHANGE_MOBILE_NUMBER.VERIFY_CURRENT_MOBILE' | translate}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 text-center pt-4 pb-4">
                    <button class="btn btn-primary pl-5 pr-5" [disabled]="!canSubmit"
                        (click)="submitForm()">{{'BUTTON.CONFIRM_DATA' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>