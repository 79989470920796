import { Component, OnInit } from '@angular/core';
import { PublicMiscControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/publicMiscController.service';
import * as WSCall from '@app/utils/WSCall';
import { CourseCategoryResponse } from '@proflink/prof-link-web-ic-api-ts-angular/model/courseCategoryResponse';
import { CategoryResponse } from '@proflink/prof-link-web-ic-api-ts-angular/model/categoryResponse';
import { CourseResponse } from '@proflink/prof-link-web-ic-api-ts-angular/model/courseResponse';
import { Router } from '@angular/router';
import { ELearningService } from '@app/service/e-learning-service';

@Component({
  selector: 'app-e-learning',
  templateUrl: './e-learning.component.html',
  styleUrls: ['./e-learning.component.scss']
})
export class ELearningComponent implements OnInit {

  constructor(
    private _publicMiscControllerService: PublicMiscControllerService,
    private _eLearningService: ELearningService,
    private _router: Router,
  ) { }

  categoryResponse: Array<CategoryResponse> = [];
  courseResponses: Array<CourseResponse> = [];

  courseByCategorySelected: Array<CourseResponse> = [];
  eLearningListState: ELearningListState = ELearningListState.SHOW_CATEGORY;
  searchValue: string = "";
  ngOnInit() {

    this.loadData();

  }

  async loadData() {

    let courseCategoryResponse: CourseCategoryResponse = await this.getAllCourseElearning();

    this.categoryResponse = courseCategoryResponse.categories;
    this.courseResponses = courseCategoryResponse.courses;


    courseCategoryResponse.courses;

  }


  onSelectedCategory(categoryResponse?: CategoryResponse) {
    this.courseByCategorySelected = [];
    if (categoryResponse) {
      this.courseResponses.forEach(o => {
        if (categoryResponse.courses.includes(o.id)) {
          this.courseByCategorySelected.push(o);
        }
      });
      this._eLearningService.title.next(categoryResponse.title);
    } else {
      this.courseResponses.forEach(o => {
        this.courseByCategorySelected.push(o);
      });
      this._eLearningService.title.next("All");
    }
    this._eLearningService.courseByCategorySelected.next(this.courseByCategorySelected);
    this._router.navigate(["elearning-result"]);
  }


  async getAllCourseElearning(): Promise<CourseCategoryResponse> {


    return WSCall.wsCall(
      () => {
        return this._publicMiscControllerService.getAllCourseElearningUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  search(): void {
    this._eLearningService.title.next("Search : " + this.searchValue);
    if (this.searchValue.length > 0) {
      this.courseByCategorySelected = this.courseResponses
          .filter(e => e.code.includes(this.searchValue) || e.title.includes(this.searchValue) || e.title.toUpperCase().includes(this.searchValue.toUpperCase()));
      this._eLearningService.courseByCategorySelected.next(this.courseByCategorySelected);
    } else {
      this._eLearningService.courseByCategorySelected.next([]);
    }
    this._router.navigate(["elearning-result"]);
  }

}

export type ELearningListState = 'SHOW_CATEGORY' | 'COURSE_LIST'
export const ELearningListState = {

  SHOW_CATEGORY: 'SHOW_CATEGORY' as ELearningListState,
  COURSE_LIST: 'COURSE_LIST' as ELearningListState

}
