import { Component, Input, OnInit } from '@angular/core';
import { SessionModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/sessionModel";
import { DatePipe } from "@angular/common";
import { TrainingDateModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/trainingDateModel";
import { SessionSummaryModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/sessionSummaryModel";
import { SessionTrainingTimeSummaryModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/sessionTrainingTimeSummaryModel";
import { CourseCategoryModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/courseCategoryModel";
import { SessionCourseSummaryModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/sessionCourseSummaryModel";
import { FileStoreModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/fileStoreModel";
import { RegisterSessionControllerService, SessionDocumentResponse } from "@proflink/prof-link-web-ic-api-ts-angular";
import { SessionControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/sessionController.service";
import { ReSendEmailRequest } from "@proflink/prof-link-web-ic-api-ts-angular/model/reSendEmailRequest";
import { APIResultstring } from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring";
import * as WSCall from "@app/utils/WSCall";

@Component({
  selector: 'app-tab-course-detail',
  templateUrl: './tab-course-detail.component.html',
  styleUrls: ['./tab-course-detail.component.scss']
})
export class TabCourseDetailComponent implements OnInit {

  constructor(
    private _datePipe: DatePipe,
    private _sessionControllerService: SessionControllerService,
  ) { }

  sessionDocuments: Array<SessionDocumentResponse> = [];

  session: SessionModel;
  displayTrainingDate: string;
  totalHours: number = 0;
  summarySession: SessionSummaryModel;
  language: string = 'th';

  @Input("summarySession")
  set setSummarySession(summarySession: SessionSummaryModel) {

    if (summarySession) {
      this.summarySession = summarySession;
      this.loadData();
    }

  }

  ngOnInit() {


  }

  async loadData() {

    this.language = localStorage.getItem('language');
    let trainingDate: Date = new Date(this.summarySession.firstDate);
    let birthDaySubmit = this._datePipe.transform(trainingDate, 'dd MMM');
    let yearTraining = Number(this._datePipe.transform(trainingDate, 'yyyy'));

    yearTraining = this.language == 'th' ? yearTraining + 543 : yearTraining;
    this.displayTrainingDate = birthDaySubmit + ' ' + yearTraining;
    this.totalHours = this.summarySession.esgTimeMins + this.summarySession.ethicTimeMins + this.summarySession.nonEthicTimeMins;
    this.totalHours = this.totalHours / 60;

    console.log("sessionId : ", this.summarySession.sessionId);
    this.sessionDocuments = await this.icGetSessionDocument(this.summarySession.sessionId);


  }

  getDateTimeDisplay(sessionTrainingTimeSummary: SessionTrainingTimeSummaryModel) {

    let trainingDate: Date = new Date(sessionTrainingTimeSummary.date);
    let birthDaySubmit = this._datePipe.transform(trainingDate, 'dd MMM', null, this.language == 'th' ? null : 'en');
    let yearTraining = Number(this._datePipe.transform(trainingDate, 'yyyy'));
    yearTraining = this.language == 'th' ? yearTraining + 543 : yearTraining;
    let display = birthDaySubmit + ' ' + yearTraining;

    let trainingStartTime: Date = new Date(sessionTrainingTimeSummary.date)
    let trainingEndTime: Date = new Date(sessionTrainingTimeSummary.date)

    trainingStartTime.setHours(0, sessionTrainingTimeSummary.startMins, 0, 0);
    trainingEndTime.setHours(0, sessionTrainingTimeSummary.endMins, 0, 0);

    let minStartDisplay = this._datePipe.transform(trainingStartTime, 'HH.mm');
    let minEndDisplay = this._datePipe.transform(trainingEndTime, 'HH.mm');

    display = display + " " + minStartDisplay + " - " + minEndDisplay;

    return display;

  }

  getDisplayCourseCategory(courseCategory: CourseCategoryModel) {

    let categoryName = courseCategory.category.categoryName;
    let hours = courseCategory.periodMins / 60;

    let display = categoryName + " " + hours;

    return display;

  }

  getFileType(file: FileStoreModel) {

    let typeFileSplit = file.fileName.split(".")
    let typeFile = typeFileSplit[typeFileSplit.length - 1];
    return typeFile;

  }

  getDateTime(date: Date) {
    let displayDate: Date = new Date(date);
    let timeDayMonth = this._datePipe.transform(displayDate, 'dd/MM');
    let yearTraining = Number(this._datePipe.transform(displayDate, 'yyyy'));
    let hoursMinute = this._datePipe.transform(displayDate, 'HH:mm');
    yearTraining = yearTraining + 543;
    let display = timeDayMonth + '/' + yearTraining + " " + hoursMinute;

    return display;
  }

  async icGetSessionDocument(sessionId: number): Promise<Array<SessionDocumentResponse>> {
    return WSCall.wsCall(
      () => {
        return this._sessionControllerService.icGetSessionDocumentUsingGET(sessionId);
      },
      respBody => {
        return respBody.data;
      }
    )
  }


}
