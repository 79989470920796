import { Component, EventEmitter, OnInit } from '@angular/core';
import { RegisterICTemporaryAccountModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel";
import { ResultOTPModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/resultOTPModel";
import * as WSCall from "@app/utils/WSCall";
import { AccountControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/accountController.service";
import { AppState } from "@core/app-state";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private _accountControllerService: AccountControllerService,
    private _appState: AppState,
    private _translate: TranslateService
  ) {
    this._translate.use(localStorage.getItem('language'));
  }

  resultOTPModel: ResultOTPModel
  token: string = '';
  verifySuccess = false;
  title: string = 'ยืนยันหมายเลข OTP';

  async ngOnInit() {
    this._translate.get('REGISTER_PAGE.T_CONFIRM_OTP').subscribe((res: string) => {
      this.title = res;
    });

  }

  async ngAfterViewInit() {

    await this.resendForgotPin(this._appState.tempUser);
  }


  async resendForgotPin(username: string) {
    let formResendOtp: RegisterICTemporaryAccountModel = {
      username: this._appState.tempUser
    };

    let resultOtp: ResultOTPModel = await this.forgotPin(formResendOtp);
    this.resultOTPModel = resultOtp;


  }

  async forgotPin(fromResendForgotPin: RegisterICTemporaryAccountModel): Promise<ResultOTPModel> {

    return WSCall.wsCall(
      () => {
        return this._accountControllerService.forgotPinUsingPOST(fromResendForgotPin);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  onVerifyOTPSuccess(token: string) {

    this.token = token;
    this.verifySuccess = true;
    this.title = 'สร้างรหัส PIN';
    this._translate.get('PIN.TITLE').subscribe((res: string) => {
      this.title = res;
    });
  }


}
