import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DatePipe } from "@angular/common";
import { TrainingDateModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/trainingDateModel";
import { ProvinceModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/provinceModel";
import { flyInAnimation } from "@shared/animations/animation";
import { ActivatedRoute, Router } from "@angular/router";
import * as WSCall from "@app/utils/WSCall";
import { RegisterSessionControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/registerSessionController.service";
import { RegisterSessionModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/registerSessionModel";
import { SessionControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/sessionController.service";
import { SessionModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/sessionModel";
import { PublicSessionControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/publicSessionController.service";
import { SessionSummaryModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/sessionSummaryModel";
import { AppState } from '@core/app-state';
import { AuthProvider } from '@core/auth/auth-provider.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-course-detail-share',
  templateUrl: './my-course-detail-share.component.html',
  styleUrls: ['./my-course-detail-share.component.scss'],
  animations: [flyInAnimation]
})
export class MyCourseDetailShareComponent implements OnInit {

  constructor(
    private _route: ActivatedRoute,
    private _datePipe: DatePipe,
    private _router: Router,
    private _registerSessionController: RegisterSessionControllerService,
    private _sessionControllerService: SessionControllerService,
    private _publicSessionControllerService: PublicSessionControllerService,
    private _appState: AppState,
    private _authProvider: AuthProvider,
    private _translate: TranslateService

  ) {
    this._translate.use(localStorage.getItem('language'));
  }

  showDataCheckInCheckout: boolean = false;
  activeId: number = 1;
  totalHours: number = 0;

  @Output('backPageCallback')
  backPageCallback: EventEmitter<Boolean> = new EventEmitter();

  registerSession: RegisterSessionModel;
  session: SessionModel;
  summarySession: SessionSummaryModel;

  displayTrainingDate: string = '';
  location: string = '';
  holdResult: Boolean;
  public color: string;
  status: string = '';
  platform: string = '';

  pathCallBack: string = 'home';
  language: string;

  callBackLoadData(_this: any) {
    _this.loadData();
  }

  async ngOnInit() {
    this.language = localStorage.getItem('language');
    let refresh_token = this._route.snapshot.queryParams['refresh_token'];
    this.platform = this._route.snapshot.queryParams['platform'];

    if (refresh_token != null && refresh_token.length > 0) {
      this._appState.loadDataWithRefreshToken(refresh_token, this, this.callBackLoadData);
    } else {
      this.loadData();
    }

  }

  async loadData() {
    const routeParams = this._route.snapshot.paramMap;
    let sessionId = Number(routeParams.get('sessionId'));
    let flagShowData = Number(routeParams.get('flagShowData'));
    let pathBack = routeParams.get('pathBack');

    if (pathBack.length > 0) {
      this.pathCallBack = pathBack;
    }



    if (flagShowData > 0) {
      this.showDataCheckInCheckout = true;
    } else {
      this.activeId = 2;
    }

    this.registerSession = await this.getRegisterSessionBySid(sessionId);
    this.setHoldResult(this.registerSession);
    this.session = await this.getSessionById(sessionId, "SessionWithTrainingInfo");
    this.summarySession = await this.getSessionSummaryBySessionId(sessionId);

    this.displayTrainingDate = this.getPeriodTrainingDayDisplay(this.session.trainingDates);
    let province: ProvinceModel = this.session.province;

    if (province != null) {
      this.location = this.language == 'th' ? province.provinceTh : this.language == 'en' ? province.provinceEn : '';
    }
  }

  setHoldResult(registerSession: RegisterSessionModel) {

    if (registerSession == null) {
      return;
    }

    let holdResult = registerSession.holdResult;

    if (holdResult != null) {
      this.holdResult = holdResult;
      if (!this.holdResult) {
        this._translate.get('HOME_PAGE.REGISTRATION_SUCCESSFUL').subscribe((res: string) => {
          this.status = res;
        });
        this.color = 'green';
      } else {
        this._translate.get('MY_COURSE.STATUS_HOLD').subscribe((res: string) => {
          this.status = res;
        });
        this.color = 'red';
      }
    }

  }

  backToList() {

    this.backPageCallback.emit(true);

  }

  getPeriodTrainingDayDisplay(trainingdays: Array<TrainingDateModel>) {

    let displayDate: string = '';
    if (trainingdays.length <= 1) {

      let trainingDate: Date = new Date(trainingdays[0].trainingDate);
      let birthDaySubmit = this._datePipe.transform(trainingDate, 'dd MMM', null, this.language == 'en' ? 'en' : null);
      let yearTraining = Number(this._datePipe.transform(trainingDate, 'yyyy'));

      yearTraining = this.language == 'en' ? yearTraining : yearTraining + 543;
      displayDate = birthDaySubmit + ' ' + yearTraining;

    } else {

      let firstTrainingDate: Date = new Date(trainingdays[0].trainingDate);
      let lastTrainingDate: Date = new Date(trainingdays[trainingdays.length - 1].trainingDate);

      let firstDayDisplay = this._datePipe.transform(firstTrainingDate, 'dd MMM', null, this.language == 'en' ? 'en' : null);
      let lastDayDisplay = this._datePipe.transform(lastTrainingDate, 'dd MMM', null, this.language == 'en' ? 'en' : null);

      let yearTraining = Number(this._datePipe.transform(firstTrainingDate, 'yyyy'));

      yearTraining = this.language == 'en' ? yearTraining : yearTraining + 543;
      displayDate = firstDayDisplay + ' - ' + lastDayDisplay + ' ' + yearTraining;
    }

    return displayDate;
  }

  async getRegisterSessionBySid(sid: number): Promise<RegisterSessionModel> {
    return WSCall.wsCall(
      () => {
        return this._registerSessionController.getRegisterSessionBySidUsingGET(sid);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getSessionSummaryBySessionId(sid: number): Promise<SessionSummaryModel> {
    return WSCall.wsCall(
      () => {
        return this._publicSessionControllerService.getSessionSummaryBySessionIdUsingGET(sid);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getSessionById(sid: number, mode: string): Promise<SessionModel> {
    return WSCall.wsCall(
      () => {
        return this._sessionControllerService.getSessionByIdUsingGET1(sid, mode);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  onPageCallback(result: boolean) {
    this._router.navigate([this.pathCallBack]);
  }
}
