<div [formGroup]="registerMobileForm">
    <div class="mt-3 custom-input">

        <div class="form-group m-0">
            <label class="register-info">เบอร์โทรศัพท์มือถือ (10 หลัก)<span class="text-danger">*</span></label>
        </div>

        <div class="form-group">
            <input class="form-control" formControlName="mobileNumber" maxlength="10" />
            <div class="text-left" *ngIf="f.mobileNumber.touched && f.mobileNumber.invalid">
                <div class="text-danger" *ngIf="f.mobileNumber.errors.required">กรุณากรอกเบอร์โทรศัพท์มือถือ
                </div>
                <div class="text-danger" *ngIf="f.mobileNumber.errors.pattern">กรุณากรอกเฉพาะตัวเลขให้ครบ 10 หลัก
                </div>
            </div>
        </div>

        <div class="form-group m-0">
            <label class="register-info">ยืนยันเบอร์โทรศัพท์มือถือ<span class="text-danger">*</span></label>
        </div>
        <div class="form-group">
            <input class="form-control" formControlName="mobileNumberConfirm" maxlength="10" />
            <div class="text-left" *ngIf="f.mobileNumberConfirm.touched && f.mobileNumberConfirm.invalid">
                <div class="text-danger" *ngIf="f.mobileNumberConfirm.errors.required">
                    กรุณากรอกยืนยันเบอร์โทรศัพท์มือถือ
                </div>
                <div class="text-danger" *ngIf="f.mobileNumberConfirm.errors.pattern">กรุณากรอกเฉพาะตัวเลขให้ครบ 10 หลัก
                </div>
            </div>
        </div>

        <!-- <div class="form-group m-0">
            <label class="register-info">อีเมล<span class="text-danger">*</span></label>
        </div>
        <div class="form-group">
            <input type="email" class="form-control" formControlName="email" />
            <div class="text-left" *ngIf="f.email.touched && f.email.invalid">
                <div class="text-danger" *ngIf="f.email.errors.required">กรุณากรอก email
                </div>
            </div>
        </div>

        <div class="form-group m-0">
            <label class="register-info">ยืนยันอีเมล<span class="text-danger">*</span></label>
        </div>

        <div class="form-group">
            <input type="email" class="form-control" formControlName="emailConfirm" />
            <div class="text-left" *ngIf="f.emailConfirm.touched && f.emailConfirm.invalid">
                <div class="text-danger" *ngIf="f.emailConfirm.errors.required">กรุณายืนยัน email
                </div>
            </div>
            <div class="text-left text-mute">โปรดตรวจสอบอีเมลของท่าน ระบบจะส่งข้อความไปยังอีเมลที่ท่านระบุ
                และกรุณาคลิกลิงค์เพื่อยืนยันความถูกต้องของอีเมล</div>
        </div> -->

        <div class="form-group pt-5">
            <button type="button" [disabled]="!canSubmit" class="btn btn-block btn-primary btn-next"
                (click)="submitForRegister()">ลงทะเบียน
            </button>
        </div>

    </div>
</div>
