<div>
    <div class="text-center">

        <img [src]="profileLink" width="100px" height="100px"
            onerror="this.src='../../../../../assets/image/unknown_person.jpeg'">
    </div>
    <div>
        <label>{{'PROFILE_PAGE.T_FULLNAME' | translate }}</label>
        <p>{{displayName}}</p>
    </div>
    <div>
        <label>{{'PROFILE_PAGE.T_BRITHDAY' | translate}}</label>
        <p *ngIf="language == 'th'">{{profile.birthdayTh}}</p>
        <p *ngIf="language == 'en'">{{profile.birthdayEn}}</p>
    </div>
    <div>
        <label>{{'PROFILE_PAGE.T_NATIONALITY' | translate}}</label>
        <p *ngIf="language == 'th'">{{profile.nationality.nationalityTh}}</p>
        <p *ngIf="language == 'en'">{{profile.nationality.nationalityEn}}</p>
    </div>
    <div *ngIf="profile.nationality.nationalityCode == 'TH'">
        <label>{{'PROFILE_PAGE.T_CARD' | translate}}</label>
        <p>{{profile.idCard}}</p>
    </div>
    <div *ngIf="profile.nationality.nationalityCode != 'TH'">
        <label>{{'PROFILE_PAGE.T_PASSPORT' | translate}}</label>
        <p>{{profile.passport}}</p>
    </div>
    <button *ngIf="canEdit" type="button" class="btn btn-block un-submit bg-primary"
        (click)="edit()">{{'BUTTON.CHANGE_DATA' | translate}}</button>
</div>