import { Component, ElementRef, Input, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { ResultOTPModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/resultOTPModel";
import { RegisterICTemporaryAccountModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel";
import { AppState } from "@core/app-state";
import * as WSCall from "@app/utils/WSCall";
import { AuthProvider } from "@core/auth/auth-provider.service";
import { APIResultstring } from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring";
import { AccountControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/accountController.service";
import * as Utils from "@app/utils/Utils.ts";
import { CreateICTemporaryAccountModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/createICTemporaryAccountModel";

import { timer, Subscription } from "rxjs";
import { TimerService } from "@app/service/timer-service";
import { Router } from "@angular/router";
import { APIResultboolean, ConsentVersionControllerService } from '@proflink/prof-link-web-ic-api-ts-angular';

@Component({
    selector: 'app-otp',
    templateUrl: './otp.component.html',
    styleUrls: ['./otp.component.scss']
})
export class OTPComponent implements OnInit {

    constructor(
        private _appState: AppState,
        private _authProvider: AuthProvider,
        private _router: Router,
        private _accountControllerService: AccountControllerService,
        private _timerService: TimerService,
        private _consentVersionControllerService: ConsentVersionControllerService,
    ) { }

    ngOnInit(): void {
    }

    @ViewChild('otpInput')
    otpInput: ElementRef;

    @Input("resultOTP")
    resultOTP: ResultOTPModel;

    isSendOtp: boolean = false;

    refCode: string = '';

    mobileNumber: string = '';
    email: string = undefined;
    resultOTPModel: ResultOTPModel = null;
    canRequestOtp: boolean = true;

    countDown: Subscription;
    counter = 1800;
    tick = 1000;

    async ngAfterViewInit() {

        await this.requestOtp();
        this.otpInput.nativeElement.focus();

    }

    async submitOtp() {
        let icId = this._appState.currentUser.icId;
        let deviceToken = await this._authProvider.validateAndCreateDeviceToken();
        let otp = this.otpInput.nativeElement.value;

        let formResendOtp: RegisterICTemporaryAccountModel = {
            "mobileNumber": this.resultOTPModel.mobileNumber,
            "refCode": this.resultOTPModel.refCode,
            "otpType": "",
            "pinCodeOTP": otp,
            "uniqueToken": deviceToken,
            "icId": icId
        };

        this.otpInput.nativeElement.value = '';

        let resultVerifyOtp: APIResultstring = await this.verifyOtp(formResendOtp);
        if (resultVerifyOtp.resultCode == 'SUCCESS') {

            let formRequestNewDevice: CreateICTemporaryAccountModel = {
                icId: icId,
                password: this._appState.tempPassword,
                uniqueToken: deviceToken,
                osName: "WEB",
                osVersion: "3.0.0"
            };

            await this.addNewDevice(formRequestNewDevice);

            console.log(this._appState.tempUser);
            console.log(this._appState.tempPassword);

            this._appState.login(this._appState.tempUser, this._appState.tempPassword, deviceToken).then(async result => {

                let resultCheckConsent = await this.checkNewConsentApp();

                if (resultCheckConsent.data) {
                    this._router.navigate(["login-consent"]);
                } else {
                    this._router.navigate(["home"]);
                }
            });

        } else {
            this.otpInput.nativeElement.value = '';
            Utils.error(resultVerifyOtp.errorMessage, 'เกิดข้อผิดพลาด');
        }

    }

    async requestOtp() {
        if (!this.canRequestOtp) {
            return;
        }

        let icId = this._appState.currentUser.icId;
        let fromResendOtp: RegisterICTemporaryAccountModel = {
            icId: icId
        };

        let resultOTPModel: ResultOTPModel = await this.resendOTPUsingPOST(fromResendOtp);
        this.refCode = resultOTPModel.refCode;
        this.mobileNumber = resultOTPModel.mobileNumber;
        this.resultOTPModel = resultOTPModel;
        this.email = resultOTPModel.email;

        let currentDate: Date = new Date();
        let expiredDate: Date = new Date(resultOTPModel.expireDatetime);

        let duration = expiredDate.valueOf() - currentDate.valueOf();
        let expiredSeconds = Math.floor(duration / 1000);

        this.counter = expiredSeconds;

        this.canRequestOtp = false;
        this.countDown = this._timerService
            .getCounter(this.tick)
            .subscribe(() => {
                if (this.counter <= 0) {
                    this.canRequestOtp = true;
                    this.countDown = null;
                } else {
                    this.counter--;
                }
            });

        this.otpInput.nativeElement.focus();

    }

    async verifyOtp(fromResendOtp: RegisterICTemporaryAccountModel): Promise<APIResultstring> {

        return WSCall.wsCall(
            () => {
                return this._accountControllerService.verifyOTPUsingPOST(fromResendOtp);
            },
            respBody => {
                return respBody;
            }
        );

    }


    async addNewDevice(formRequestNewDevice: CreateICTemporaryAccountModel): Promise<APIResultstring> {

        return WSCall.wsCall(
            () => {
                return this._accountControllerService.addNewDeviceUsingPOST(formRequestNewDevice);
            },
            respBody => {
                return respBody;
            }
        );

    }

    validateInputAndSubmitOtp() {
        let otp = this.otpInput.nativeElement.value;
        if (otp.length >= 4) {
            this.submitOtp();
        }
    }

    async resendOTPUsingPOST(fromResendOtp: RegisterICTemporaryAccountModel): Promise<ResultOTPModel> {

        return WSCall.wsCall(
            () => {
                return this._accountControllerService.resendOTPUsingPOST(fromResendOtp);
            },
            respBody => {
                return respBody.data;
            }
        );
    }

    ngOnDestroy() {
        this.countDown = null;
    }

    async checkNewConsentApp(): Promise<APIResultboolean> {
        return WSCall.wsCall(
            () => {
                return this._consentVersionControllerService.checkNewConsentAppUsingGET();
            },
            respBody => {
                return respBody;
            }
        );
    }


}


